// Utils
import { createNamespace } from '../utils';

// Components

const [createComponent, bem] = createNamespace('card');

export default createComponent({
  props: {},

  setup(props, { slots, emit }) {
    return () => {
      return <div class={bem()}>{slots.default ? slots.default() : ''}</div>;
    };
  },
});
