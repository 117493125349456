export default class Watcher {
  $data: { [key: string]: any };

  $watch: { [key: string]: Function };

  constructor(data: { [key: string]: any }, watch: { [key: string]: Function }) {
    this.$data = data;
    this.$watch = watch;
    Object.keys(data).forEach((key) => {
      this.setData(key);
    });
  }

  setData(_key: any) {
    Object.defineProperty(this, _key, {
      get() {
        return this.$data[_key];
      },
      set(val) {
        const oldVal = this.$data[_key];
        if (oldVal === val) return val;
        this.$data[_key] = val;
        this.$watch[_key] && this.$watch[_key].call(this, val, oldVal);
        return val;
      },
    });
  }
}
