
  import { defineComponent } from 'vue';

  declare const window: any;

  export default defineComponent({
    props: {
      questionList: {
        type: Object,
      },
    },
    setup() {
      const openVip = () => {
        const ua = navigator.userAgent.toLowerCase();
        const isTTWebView = ua.includes('toutiaomicroapp');
        const isWeixin = ua.includes('micromessenger');

        if (isTTWebView) {
          window.tt.miniProgram.navigateTo({
            url: '/pages/module/open/index',
          });
        } else if (isWeixin) {
          window.wx.miniProgram.navigateTo({
            url: '/pages/module/open/service',
          });
        } else {
          window.ks.navigateTo({
            url: '/pages/module/open/index',
          });
        }
      };
      return { openVip };
    },
  });
