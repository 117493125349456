<template>
  <div class="courseware">
    <mk-skeleton
      :loading="loading"
      :row="2"
      :row-style="[
        {
          width: '100%',
          height: 153,
          icon: true,
          iconSize: 60,
        },
        {
          width: '100%',
          height: 153,
          icon: true,
          iconSize: 60,
        },
      ]"
      round
    >
      <img v-for="item in coursewareList" :key="item.id" :src="item.url" alt="" />
    </mk-skeleton>
    <mk-empty v-if="!coursewareList || !coursewareList.length" description="暂无相关课件"></mk-empty>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import MkSkeleton from '@/mk-view/skeleton';
  import MkEmpty from '@/mk-view/empty';

  export default defineComponent({
    components: {
      MkSkeleton,
      MkEmpty,
    },
    props: {
      coursewareList: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    setup() {
      const loading = ref(true);
      onMounted(() => {
        loading.value = false;
      });
      return { loading };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';
  .courseware {
    min-height: 100%;
    padding: px(10);
    background: white;
    img {
      margin-bottom: px(8);
      border-radius: px(4);
    }
  }
</style>
