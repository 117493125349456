
  import { defineComponent, onMounted, ref } from 'vue';
  import type { Ref } from 'vue';
  import { useRoute } from 'vue-router';
  import MkTabs from '@/mk-view/tabs';
  import MkTab from '@/mk-view/tab';
  import CustomVideo from '@/components/video.vue';
  import { getSeriesDetail, getQuestionList, Series, Author, Course } from '@/service';
  import { trackEvent } from '@/plugins/track/events';
  import storage from '@/common/storage';
  import VideoAuthorIntro from '../components/video-author-intro.vue';
  import { ICourseware } from '../components/types';
  import SeriesCourseList from './components/series-course-list.vue';
  import SeriesCourseListDetail from './components/series-course-list-detail.vue';
  // import QuestionList from '../components/question-list.vue';
  // import QuestionAction from '../components/question-action.vue';
  import Courseware from '../components/courseware.vue';
  import OpenVip from '../components/open-vip.vue';
  import OpenFormal from '../components/open-formal.vue';

  export default defineComponent({
    components: {
      MkTabs,
      MkTab,
      VideoAuthorIntro,
      CustomVideo,
      SeriesCourseList,
      SeriesCourseListDetail,
      // QuestionList,
      // QuestionAction,
      Courseware,
      OpenVip,
      OpenFormal,
    },
    props: {},
    setup() {
      const route = useRoute();
      const isVip = ref(true);
      const isSVip = ref(true);
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';
      const sub_course_id = route.query.sub_course_id ? Number(route.query.sub_course_id) : '';
      const seriesDetail: Ref<Series> = ref(new Series());
      const videoIntro: Ref<any> = ref({
        pv: 0,
        title: '',
        description: '',
        cover_img: '',
        course_type: 1,
      });
      const author = ref({});
      const seriesCourseList: Ref<Course[]> = ref([]);
      const activedCourse: Ref<Course> = ref(new Course());
      const activedCoursewareList: Ref<ICourseware[]> = ref([]);

      // 激活课程
      const onActiveCourse = async (course: Course) => {
        if (activedCourse.value.course_id === course.course_id) {
          return;
        }
        trackEvent(
          '2247',
          route,
          {},
          {
            course_id: course.course_id,
          },
        );
        document.title = course.course_name;
        activedCourse.value = course;
        activedCoursewareList.value = course.section_list.map((item) => {
          return {
            id: item.id,
            url: item.image_url,
          };
        });
        await loadQuestionList();
      };

      const onEnded = () => {
        const index = seriesCourseList.value.findIndex((item) => {
          return item.course_id === activedCourse.value.course_id;
        });
        if (index >= seriesCourseList.value.length - 1) {
          return;
        }
        onActiveCourse(seriesCourseList.value[index + 1]);
      };

      const question = ref('');
      const questionList = ref([{}]);
      const loadQuestionList = async () => {
        const { question_list } = await getQuestionList({
          course_id: activedCourse.value.course_id,
          offset: 0,
          count: 100,
        });

        questionList.value = question_list.map((item) => {
          try {
            item.content = JSON.parse(item.content);
          } catch (e) {
            console.log(e);
          }
          return item;
        });
      };
      const onSendSuccessQuestion = () => {
        loadQuestionList();
      };

      const seriesCourseListDetailVisible = ref(false);

      const onShowSeriesCourseListDetail = () => {
        trackEvent('2246', route, {});
        seriesCourseListDetailVisible.value = true;
      };

      const onHideSeriesCourseListDetail = () => {
        seriesCourseListDetailVisible.value = false;
      };

      const onClickTab = (tab: any) => {
        if (tab === 1) {
          trackEvent('2244', route, {});
        }
        if (tab === 2) {
          trackEvent('2245', route, {});
        }
      };
      onMounted(async () => {
        if (route.query.token) {
          storage.set('access_token', route.query.token);
        }
        if (route.query.is_vip) {
          isVip.value = !!Number(route.query.is_vip);
        } else {
          isVip.value = true;
        }
        if (route.query.is_svip) {
          isSVip.value = !!Number(route.query.is_svip);
        } else {
          isSVip.value = true;
        }
        const series = await getSeriesDetail({
          id,
          community_id,
        });

        seriesDetail.value = series.course_detail;

        videoIntro.value = {
          pv: series.pv || 0,
          title: seriesDetail.value.course_name,
          description: seriesDetail.value.info,
          cover_img: series.cover_img,
          course_type: seriesDetail.value.course_type,
        };

        seriesCourseList.value = seriesDetail.value.sub_course_detail_list;

        seriesCourseList.value.forEach((item: any) => {
          item.course_id = item.id;
          item.course_name = item.name;
        });

        author.value = new Author(seriesDetail.value);

        if (!sub_course_id) {
          onActiveCourse(seriesCourseList.value[0]);
        } else {
          const course = seriesCourseList.value.find((item) => {
            return item.course_id === sub_course_id;
          });
          if (course) {
            onActiveCourse(course);
          }
        }
      });

      return {
        isVip,
        isSVip,
        onClickTab,
        onEnded,
        seriesDetail,
        videoIntro,
        author,
        seriesCourseList,
        activedCourse,
        activedCoursewareList,
        seriesCourseListDetailVisible,
        question,
        onActiveCourse,
        onShowSeriesCourseListDetail,
        onHideSeriesCourseListDetail,
        questionList,
        onSendSuccessQuestion,
      };
    },
  });
