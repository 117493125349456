export interface ICourse {
  author: string;
  author_id: number;
  head_url: string;
  title: string;
  course_id: number;
  course_name: string;
  duration: number;
  course_type: number;
  cloud_url: string;
  info: string;
  section_list?: ISectionList[];
}

export interface ISectionList {
  id: number;
  image_url: string;
}

export class Course implements ICourse {
  author = '';

  author_id = 0;

  head_url = '';

  title = '';

  course_id = 0;

  course_name = '';

  duration = 0;

  course_type = 1;

  cloud_url = '';

  info = '';

  section_list: ISectionList[] = [];

  constructor(data?: ICourse) {
    if (!data) {
      return;
    }
    this.author = data.author;
    this.author_id = data.author_id;
    this.head_url = data.head_url;
    this.title = data.title;
    this.course_id = data.course_id;
    this.course_type = data.course_type;
    this.course_name = data.course_name;
    this.duration = data.duration;
    this.cloud_url = data.cloud_url;
    this.info = data.info;
    if (data.section_list) this.section_list = data.section_list;
  }
}
