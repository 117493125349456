import { PropType } from 'vue';
import { createNamespace, addUnit, getSizeStyle } from '../utils';

const [createComponent, bem] = createNamespace('skeleton');
const DEFAULT_ROW_WIDTH = '100%';
const DEFAULT_LAST_ROW_WIDTH = '60%';

export default createComponent({
  props: {
    type: String,
    round: {
      type: Boolean,
      default: true,
    },
    titleWidth: [Number, String],
    avatarSize: [Number, String],
    avatarShape: {
      type: String as PropType<'square' | 'round'>,
      default: 'round',
    },
    row: {
      type: [Number, String],
      default: 0,
    },
    rowStyle: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: true,
    },
    animate: {
      type: Boolean,
      default: true,
    },
    avatarStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  setup(props, { slots }) {
    const getRowWidth = (index: number) => {
      const { rowStyle } = props;

      if (Array.isArray(rowStyle)) {
        return rowStyle[index].width;
      } else if ((rowStyle as any).width === DEFAULT_ROW_WIDTH && index === +props.row - 1) {
        return DEFAULT_LAST_ROW_WIDTH;
      }

      return (rowStyle as any).width;
    };

    const getRowHeight = (index: number) => {
      const { rowStyle } = props;

      if (Array.isArray(rowStyle)) {
        return rowStyle[index].height;
      }

      return (rowStyle as any).height;
    };

    const getMarginBottom = (index: number) => {
      const { rowStyle } = props;

      if (Array.isArray(rowStyle)) {
        return rowStyle[index].marginBottom;
      }

      return (rowStyle as any).marginBottom;
    };

    const getRowStyle = (index: number) => {
      const style: any = {};
      style.width = addUnit(getRowWidth(index));
      style.height = addUnit(getRowHeight(index));
      style.marginBottom = addUnit(getMarginBottom(index));
      return style;
    };

    const getAvatarStyle = (index: number) => {
      const style: any = {};
      style.width = addUnit(getRowWidth(index));
      style.height = addUnit(getRowHeight(index));
      style.marginBottom = addUnit(getMarginBottom(index));
      return style;
    };

    const renderAvatar = () => {
      if (props.type === 'avatar') {
        return (
          <div class={bem('avatar', props.avatarShape)} style={getSizeStyle(props.avatarSize)}>
            <i
              style={{ fontSize: addUnit((props.avatarStyle as any).iconSize) }}
              class="iconfont iconlogo"
            ></i>
          </div>
        );
      }
    };

    const renderTitle = () => {
      if (props.type === 'title') {
        return <h3 class={bem('title')} style={{ width: addUnit(props.titleWidth) }} />;
      }
    };

    const renderIcons = (index: number) => {
      const { rowStyle } = props;

      if (Array.isArray(rowStyle) && rowStyle[index].icon) {
        return (
          <i style={{ fontSize: addUnit(rowStyle[index].iconSize) }} class="iconfont iconlogo"></i>
        );
      }

      if ((rowStyle as any).icon) {
        return (
          <i
            style={{ fontSize: addUnit((rowStyle as any).iconSize) }}
            class="iconfont iconlogo"
          ></i>
        );
      }
    };

    const renderRows = () => {
      const Rows: JSX.Element[] = [];

      for (let i = 0; i < props.row; i++) {
        Rows.push(
          <div class={bem('row')} style={getRowStyle(i)}>
            {renderIcons(i)}
          </div>,
        );
      }

      return Rows;
    };

    return () => {
      if (!props.loading) {
        return slots.default?.();
      }

      return (
        <div class={bem({ animate: props.animate, round: props.round })}>
          {renderAvatar()}
          <div class={bem('content')}>
            {renderTitle()}
            {renderRows()}
          </div>
        </div>
      );
    };
  },
});
