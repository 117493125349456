/**
 * @desc 模拟埋点Action
 */
import storage from '@/common/storage';
import request from '@/service/core/request';

export default function trackAction(evt: any, addtional = {}) {
  const data = {
    evt,
    ...addtional,
    action_time: Date.now(),
  };
  /** *
   * 组合参数
   * @link http://confluence.mockuai.com/pages/viewpage.action?pageId=12102822
   * @param _data
   * @returns {*}
   * "type": "show",
   * "page_id": "",
   * "tag": "0000,0000", 模块,操作点
   * "trace_log": "10,1003", 来源,页面
   * "options": ""
   */
  function handleParameter(_data: any) {
    const tempData = {
      time: Date.now(), // 发生时间
      a_route: '',
      a_scene: evt,
      a_options: {},
      extend_options: {},
      user_agent: window.navigator.userAgent,
      language: window.navigator.language,
      net_type: getNetworkType(),
      net_connected: true,
      user_id: (storage.get('user') || {}).user_id,
    };
    const obj = Object.assign(_data, tempData);
    return obj;
  }
  function getNetworkType() {
    const ua = navigator.userAgent;
    const uaArr = ua.match(/NetType\/\w+/) || [];
    let networkStr = uaArr.length ? uaArr[0] : 'NetType/other';
    networkStr = networkStr.toLowerCase().replace('nettype/', '');
    let networkType;
    switch (networkStr) {
      case 'wifi':
        networkType = 'wifi';
        break;
      case '4g':
        networkType = '4g';
        break;
      case '3g':
        networkType = '3g';
        break;
      case '3gnet':
        networkType = '3g';
        break;
      case '2g':
        networkType = '2g';
        break;
      default:
        networkType = 'other';
    }
    return networkStr;
  }

  /**
   * 缓存埋点信息
   */
  function addLog() {
    let tempLog = [];
    if (
      storage.get('log_list') &&
      storage.get('log_list').length &&
      storage.get('log_list').length >= 5
    ) {
      tempLog = storage.get('log_list');
      tempLog.push(handleParameter(data));
      sendRecord(tempLog);
      storage.remove('log_list');
    } else if (storage.get('log_list') && storage.get('log_list').length) {
      tempLog = storage.get('log_list');
      tempLog.push(handleParameter(data));
      storage.set('log_list', tempLog);
    } else {
      tempLog.push(handleParameter(data));
      storage.set('log_list', tempLog);
    }
  }

  /**
   * 发送埋点信息
   * @param _data
   */
  async function sendRecord(_data: any) {
    try {
      await request.request({
        url: 'ec/c/data/event/collect',
        data: {
          event: JSON.stringify(_data),
        },
        method: 'POST',
        needLogin: false,
      });
    } catch (e) {
      let tempLog = [];
      if (storage.get('log_list') && storage.get('log_list').length) {
        tempLog = storage.get('log_list');
        tempLog.concat(_data);
        storage.set('log_list', tempLog);
      } else {
        storage.set('log_list', _data);
      }
    }
  }

  if (evt === 'p_show') {
    sendRecord([handleParameter(data)]);
  } else {
    addLog();
  }
}
