<template>
  <mk-popup v-model:show="popupVisible" position="bottom" :overlay="false" close-on-click-overlay>
    <div class="series-course-list-detail">
      <div class="header">
        <div class="title">
          课程列表
          <span class="title__secondary">共{{ seriesCourseList.length }}集</span>
        </div>
        <div class="cut" @click="onClickHideSeriesCourseListDetail">
          <i class="iconfont iconclose"></i>
        </div>
      </div>
      <div class="content">
        <div
          v-for="(item, index) in seriesCourseList"
          :key="item.course_id"
          class="series-course"
          :class="{ 'series-course--active': item.course_id === activedCourse.course_id }"
          @click="onClickActiveCourse(item)"
        >
          <div class="series-course__left">
            <div class="series-course__serial">第{{ index + 1 }}集</div>
            <div class="series-course__duration">{{ filterDuration(item.duration) }}</div>
          </div>
          <div class="series-course__right">
            <div class="series-course__name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </mk-popup>
</template>

<script lang="ts">
  import { defineComponent, ref, watch, toRefs, nextTick } from 'vue';
  import MkPopup from '@/mk-view/popup';
  import Utils from '@mk/mk-utils';

  export default defineComponent({
    components: { MkPopup },
    emits: ['activeCourse', 'hideSeriesCourseListDetail'],
    props: {
      seriesCourseList: {
        type: Array,
        default: () => [],
      },
      activedCourse: {
        type: Object,
        default: () => ({}),
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const onClickActiveCourse = (course: any) => {
        emit('activeCourse', course);
      };
      const popupVisible = ref(props.visible);
      const { visible } = toRefs(props);

      watch(visible, (newValue) => {
        popupVisible.value = newValue;
        positionActivedCourse(props);
      });

      const onClickHideSeriesCourseListDetail = () => {
        emit('hideSeriesCourseListDetail', props.activedCourse);
      };

      watch(props, (value) => {
        positionActivedCourse(value);
      });

      // 定位课程位置
      const positionActivedCourse = (value: any) => {
        nextTick(() => {
          const { seriesCourseList, activedCourse } = value;
          const index = seriesCourseList.findIndex((item: any) => {
            return item.course_id === activedCourse.course_id;
          });
          if (document.querySelector('.series-course-list-detail')) {
            if (index === 0) {
              (document.querySelector('.series-course-list-detail .content') as any).scrollTop = 0;
              return;
            }
            (document.querySelector('.series-course-list-detail .content') as any).scrollTop =
              70 * index -
              ((document.querySelector('.series-course-list-detail .content') as any).offsetHeight - 70) / 2;
          }
        });
      };

      const filterDuration = (value: number) => {
        return Utils.MKString.formatDuration(value, 'mm:ss');
      };
      return {
        popupVisible,
        onClickActiveCourse,
        onClickHideSeriesCourseListDetail,
        filterDuration,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .series-course-list-detail {
    position: relative;
    width: 100%;
    height: calc(100vh - 56.25vw);
    background-color: $color-bg-light;
  }
  .header {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: px(56);
    padding: 0 $box-space-large;
    .title {
      @include init-font(16, 16, $color-text-highlight);

      font-weight: bold;

      &__secondary {
        @include init-font(14, 16, #999);

        margin-left: 12px;
        font-weight: normal;
      }
    }
    .cut {
      @include init-font(20, 16, #999);
      i.iconclose {
        font-size: 20px;
      }
    }
  }
  .content {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% - #{px(56)});
    padding-bottom: env(safe-area-inset-bottom);
    overflow-y: auto;

    .series-course {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
      padding: $box-space-large;
      background: $color-bg-light;
      border-top: 1px solid $border-color-primary;

      &--active {
        background: $color-bg-primary;

        .series-course {
          &__serial {
            color: $color-primary;
          }

          &__name {
            color: $color-primary;
          }
        }
      }

      &__left {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: center;
        width: px(48);
        height: px(40);
        margin-right: $box-space-xl;
        text-align: center;
      }
      &__right {
        height: px(40);
      }

      &__serial {
        @include init-font(15, 14, $color-text-highlight);

        margin-bottom: px(10);
      }

      &__duration {
        @include init-font(12, 12, $color-text-secondary);
      }

      &__name {
        @include init-font(15, 20, $color-text-highlight);
      }
    }
  }
</style>
