
  import { defineComponent, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import type { Ref } from 'vue';
  import CustomVideo from '@/components/video.vue';
  import MkSwipe from '@/mk-view/swipe';
  import MkSwipeItem from '@/mk-view/swipe-item';
  import MkImagePreview from '@/mk-view/image-preview';
  import { getItemDetail, IContentItem, Goods } from '@/service';
  import { trackEvent } from '@/plugins/track/events';

  export default defineComponent({
    components: {
      MkSwipe,
      MkSwipeItem,
      CustomVideo,
    },
    setup() {
      const route = useRoute();
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';
      const goods: Ref<IContentItem> = ref({
        item_detail: new Goods(),
      } as IContentItem);

      const previewImage = (imageUrl: string) => {
        MkImagePreview({
          images: [imageUrl],
          showIndex: false,
        });
      };

      const onClickForward = async () => {
        trackEvent('2252', route, {});
        const { api } = window.lib.bridge;

        await api.communityGoodsForward({
          goodsId: goods.value.item_detail.item_id,
          title: goods.value.item_detail.item_desc,
          images: JSON.stringify(goods.value.item_detail.item_img),
        });
      };

      const onClickLinkToGoods = () => {
        trackEvent('2253', route, {});
        window.location.href = `mockuaiseller://seller/item?item_id=${goods.value.item_detail.item_id}&source_type=1`;
      };

      onMounted(async () => {
        goods.value = await getItemDetail({
          id,
          community_id,
        });
      });

      return {
        goods,
        previewImage,
        onClickForward,
        onClickLinkToGoods,
      };
    },
  });
