export default {
  // 主色
  primary: '#ff6000',
  // 辅色
  secondary1: '#ec4000',
  secondary2: '#ff8000',
  secondary3: '#fff2ee',
  secondary4: '#e9f4ff',
  secondary5: '#ffc292',
  secondary6: '#007BFF',
  secondary7: '#d0d0d0',
  secondary8: '#e7e7e7',

  bg: {
    primary: '#f7f7f7',
    light: '#ffffff',
  },

  text: {
    highlight: '#333333',
    primary: '#666666',
    secondary: '#999999',
    disabled: '#bbbbbb',
  },

  mask: {
    primary: '#0000007F',
    dark: '#000000B3',
  },
};
