<template>
  <div class="page article">
    <div class="article__title">
      {{ artcile.main_title }}
    </div>
    <author-intro
      v-if="artcile.article_detail.author_id"
      :author="author"
      :loading="loading"
      class="author-intro"
    ></author-intro>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="article__content" v-html="artcile.article_detail.content"></div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import type { Ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { Article, Author, getArticleDetail, IContentArticle } from '@/service';
  import AuthorIntro from '@/components/author-intro.vue';

  export default defineComponent({
    components: {
      AuthorIntro,
    },
    props: {},
    setup() {
      const route = useRoute();
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';
      const artcile: Ref<IContentArticle> = ref({
        main_title: '',
        sub_title: '',
        cover_img: '',
        description: '',
        content_type: 1,
        article_detail: new Article(),
      } as IContentArticle);

      const author: Ref<Author> = ref(new Author());
      const loading = ref(true);
      onMounted(async () => {
        artcile.value = await getArticleDetail({
          community_id,
          id,
        });

        document.title = artcile.value.main_title;

        artcile.value.article_detail.content = decodeURIComponent(artcile.value.article_detail.content);
        author.value = new Author(artcile.value.article_detail);
        loading.value = false;
      });
      return {
        artcile,
        author,
        loading,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .article {
    padding: px(12);
    background: white;

    &__title {
      @include init-font(17, 26, $color-text-highlight);

      font-weight: bold;
    }
    /deep/ &__content {
      @include init-font(14, 24, $color-text-highlight);

      word-break: break-all !important;

      img {
        display: block;
        margin: px(10) 0;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: px(10) 0;
      }
      video {
        width: 100%;
        height: auto;
        margin: px(10) 0;
      }
    }
  }
</style>
