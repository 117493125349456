
  import { defineComponent, ref, watch, toRefs, nextTick } from 'vue';
  import MkPopup from '@/mk-view/popup';
  import Utils from '@mk/mk-utils';

  export default defineComponent({
    components: { MkPopup },
    emits: ['activeCourse', 'hideSeriesCourseListDetail'],
    props: {
      seriesCourseList: {
        type: Array,
        default: () => [],
      },
      activedCourse: {
        type: Object,
        default: () => ({}),
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const onClickActiveCourse = (course: any) => {
        emit('activeCourse', course);
      };
      const popupVisible = ref(props.visible);
      const { visible } = toRefs(props);

      watch(visible, (newValue) => {
        popupVisible.value = newValue;
        positionActivedCourse(props);
      });

      const onClickHideSeriesCourseListDetail = () => {
        emit('hideSeriesCourseListDetail', props.activedCourse);
      };

      watch(props, (value) => {
        positionActivedCourse(value);
      });

      // 定位课程位置
      const positionActivedCourse = (value: any) => {
        nextTick(() => {
          const { seriesCourseList, activedCourse } = value;
          const index = seriesCourseList.findIndex((item: any) => {
            return item.course_id === activedCourse.course_id;
          });
          if (document.querySelector('.series-course-list-detail')) {
            if (index === 0) {
              (document.querySelector('.series-course-list-detail .content') as any).scrollTop = 0;
              return;
            }
            (document.querySelector('.series-course-list-detail .content') as any).scrollTop =
              70 * index -
              ((document.querySelector('.series-course-list-detail .content') as any).offsetHeight - 70) / 2;
          }
        });
      };

      const filterDuration = (value: number) => {
        return Utils.MKString.formatDuration(value, 'mm:ss');
      };
      return {
        popupVisible,
        onClickActiveCourse,
        onClickHideSeriesCourseListDetail,
        filterDuration,
      };
    },
  });
