<template>
  <div class="series-course-list">
    <div class="header">
      <div class="title">课程列表</div>
      <div class="cut" @click="onClickShowSeriesCourseListDetail">全部</div>
    </div>
    <div class="content">
      <mk-card
        v-for="item in seriesCourseList"
        :key="item.course_id"
        class="card"
        :class="{ 'card--active': item.course_id === activedCourse.course_id }"
        @click="onClickActiveCourse(item)"
      >
        <div class="card__header">{{ item.name }}</div>
        <div class="card__footer">
          <div v-if="item.course_id === activedCourse.course_id" class="card__actived-mark">
            <img src="https://mktv-in-cdn.mockuai.com/16214127792802574.gif" alt="" />
            正在播放
          </div>
          <div class="card__duration">
            {{ filterDuration(item.duration) }}
          </div>
        </div>
      </mk-card>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, nextTick, onMounted, watch } from 'vue';
  import MkCard from '@/mk-view/card';
  import Utils from '@mk/mk-utils';

  export default defineComponent({
    components: { MkCard },
    emits: ['activeCourse', 'showSeriesCourseListDetail'],
    props: {
      seriesCourseList: {
        type: Array,
        default: () => {
          return [];
        },
      },
      activedCourse: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    setup(props, { emit }) {
      const onClickActiveCourse = (course: any) => {
        emit('activeCourse', course);
      };
      const onClickShowSeriesCourseListDetail = () => {
        emit('showSeriesCourseListDetail', props.activedCourse);
      };
      const filterDuration = (value: number) => {
        return Utils.MKString.formatDuration(value, 'mm:ss');
      };
      watch(props, (value) => {
        const { seriesCourseList, activedCourse } = value;
        const index = seriesCourseList.findIndex((item: any) => {
          return item.course_id === activedCourse.course_id;
        });
        nextTick(() => {
          if (index === 0) {
            (document.querySelector('.series-course-list .content') as any).scrollLeft = 0;
            return;
          }
          (document.querySelector('.series-course-list .content') as any).scrollLeft =
            141 * index - ((document.querySelector('.series-course-list .content') as any).offsetWidth - 131) / 2;
        });
      });
      return { onClickActiveCourse, onClickShowSeriesCourseListDetail, filterDuration };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .series-course-list {
    width: 100%;
    height: 143px;
    margin-bottom: $box-space-medium;
    padding: $box-space-xl 0 0 0;
    background-color: $color-bg-light;

    .header {
      display: flex;
      justify-content: space-between;
      padding: 0 $box-space-large;
      .title {
        @include init-font(15, 16, $color-text-highlight);

        font-weight: bold;
      }
      .cut {
        @include init-font(14, 16, $color-text-primary);
      }
    }
    .content {
      display: flex;
      width: 100%;
      padding: $box-space-xl $box-space-large;
      overflow-x: auto;
      .card {
        position: relative;
        flex-shrink: 0;
        width: 131px;
        height: 79px;
        margin-right: $box-space-medium;
        padding: 6px 8px;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

        &--active {
          width: 131px;
          height: 79px;
          background: url('https://mktv-in-cdn.mockuai.com/16207271189122972.png');
          background-size: cover;
          border: 1px solid rgba(255, 96, 0, 0.5);
          box-shadow: unset;
          .card__footer {
            justify-content: space-between;
          }
        }

        &__header {
          @include ellipsis(2);

          height: 36px;
          margin-bottom: px(14);
          font-size: 13px;
          line-height: 18px;
        }

        &__footer {
          bottom: 0;
          display: flex;
          justify-content: flex-end;
          color: #999;
          font-size: 11px;
          line-height: 16px;
        }

        &__actived-mark {
          color: $color-primary;
          font-weight: bold;
          img {
            width: px(9);
            height: px(9);
          }
        }
      }
    }
  }
</style>
