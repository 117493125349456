<template>
  <div class="page material">
    <div class="copywritiing">
      {{ material.material_detail.share_copy }}
    </div>
    <mk-swipe class="swipe">
      <mk-swipe-item v-for="(item, index) in material.material_detail.material_img" :key="index">
        <div class="swipe-item__container">
          <img class="swipe-item__image" :src="item" alt="" @click="previewImage(item)" />
        </div>
      </mk-swipe-item>
    </mk-swipe>
    <div class="footer footer--fixed">
      <div class="footer__btn">
        <mk-button :color="color.primary" plain @click="onClickShare">一键转发</mk-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import color from '@/mk-view/utils/theme/color';
  import MkSwipe from '@/mk-view/swipe';
  import MkSwipeItem from '@/mk-view/swipe-item';
  import MkButton from '@/mk-view/button';
  import { getMaterialDetail } from '@/service';
  import { trackEvent } from '@/plugins/track/events';
  import MkImagePreview from '@/mk-view/image-preview';

  export default defineComponent({
    components: {
      MkSwipe,
      MkSwipeItem,
      MkButton,
    },
    props: {},
    setup() {
      const route = useRoute();
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';

      const material = ref({
        main_title: '',
        sub_title: '',
        cover_img: '',
        description: '',
        content_type: 0,
        material_detail: {
          material_img: [''],
          share_copy: '',
        },
      });

      onMounted(async () => {
        material.value = await getMaterialDetail({
          id,
          community_id,
        });
      });

      const previewImage = (imageUrl: string) => {
        MkImagePreview({
          images: [imageUrl],
          showIndex: false,
        });
      };

      const onClickShare = async () => {
        trackEvent('2248', route, {});

        const { api } = window.lib.bridge;
        await api.communityMaterialShare({
          title: material.value.material_detail.share_copy,
          images: JSON.stringify(material.value.material_detail.material_img),
        });
      };

      return {
        color,
        material,
        onClickShare,
        previewImage,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .material {
    background: white;
  }
  .swipe-item {
    &__container {
      margin-bottom: px(60);
      padding: 0 px(20) 0 px(20);
      overflow: hidden;
      background-color: $color-bg-light;
    }
    &__image {
      @include image-cover();

      border-radius: px(16);
    }
  }
  .indicator {
    position: absolute;
    right: px(5);
    bottom: px(5);
    padding: px(2) px(5);
    font-size: px(12);
    background: rgba(0, 0, 0, 0.1);
  }
  /deep/.mk-swipe__indicators {
    .mk-swipe__indicator--active {
      width: px(12);
      border-radius: px(6);
    }
  }
  .copywritiing {
    @include init-font(16, 26, $color-text-highlight);

    padding: px(8) px(24) px(8) px(24);
    background-color: $color-bg-light;
  }

  .footer {
    &--fixed {
      @include fixed-bottom;

      padding-bottom: env(safe-area-inset-bottom);
      background: white;
    }

    &__btn {
      padding: px(8) px(16);
      /deep/.mk-button {
        width: 100%;
        height: px(44);
        font-size: px(16);
      }
    }
  }
</style>
