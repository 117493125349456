import { Author } from './Author.model';
import { Article } from './Article.model';
import { Video } from './Video.model';
import { Course } from './Course.model';
import { Series } from './Series.model';
import { Goods } from './Goods.model';

/* 发圈素材 */
export interface IMaterialDetail extends Author {
  share_copy: string;
  material_img: string[];
}
export interface IContent {
  /* 主标题 */
  main_title: string;
  /* 副标题 */
  sub_title: string;
  /* 封面 */
  cover_img: string;
  /* 描述 */
  description: string;
  pv?: number;
  /* 内容属性（1：文章；2：视频；3：课程；4：课程系列；5：发圈素材；6：商品） */
  content_type: number;
  article_detail?: Article;
  video_detail?: Video;
  course_detail?: Course | Series;
  material_detail?: IMaterialDetail;
  item_detail?: Goods;
}

export class Content implements IContent {
  /* 主标题 */
  main_title: string = '';

  /* 副标题 */
  sub_title: string = '';

  /* 封面 */
  cover_img: string = '';

  /* 描述 */
  description: string = '';

  pv: number = 0;

  /* 内容属性（1：文章；2：视频；3：课程；4：课程系列；5：发圈素材；6：商品） */
  content_type: number = 0;

  article_detail?: Article;

  video_detail?: Video;

  course_detail?: Course | Series;

  material_detail?: IMaterialDetail;

  item_detail?: Goods;

  constructor(data?: IContent) {
    if (!data) {
      return;
    }
    this.main_title = data.main_title;
    this.sub_title = data.sub_title;
    this.cover_img = data.cover_img;
    this.description = data.description;
    this.content_type = data.content_type;
    if (data.pv) this.pv = data.pv;
    if (data.article_detail) this.article_detail = data.article_detail;
    if (data.video_detail) this.video_detail = data.video_detail;
    if (data.course_detail) this.course_detail = data.course_detail;
    if (data.material_detail) this.material_detail = data.material_detail;
    if (data.item_detail) this.item_detail = data.item_detail;
  }
}

/* 文章 */
export interface IContentArticle extends IContent {
  content_type: 1;
  article_detail: Article;
}
/* 视频相关 */
export interface IContentVideo extends IContent {
  pv: number;
  content_type: 2;
  video_detail: Video;
}

/* 课程  */
export interface IContentCourse extends IContent {
  pv: number;
  content_type: 3;
  course_detail: Course;
}
/* 系列课程 */
export interface IContentSeries extends IContent {
  pv: number;
  content_type: 4;
  course_detail: Series;
}
/* 发圈素材 */
export interface IContentMaterial extends IContent {
  content_type: 5;
  material_detail: IMaterialDetail;
}
/* 发圈素材 */
export interface IContentItem extends IContent {
  content_type: 6;
  item_detail: Goods;
}

export function IsArticleDetail(data: IContent): data is IContentArticle {
  return data.content_type === 1;
}

export function IsVideoDetail(data: IContent): data is IContentVideo {
  return data.content_type === 2;
}

export function IsCourseDetail(data: IContent): data is IContentCourse {
  return data.content_type === 3;
}

export function IsSeriesDetail(data: IContent): data is IContentSeries {
  return data.content_type === 4;
}

export function IsMaterialDetail(data: IContent): data is IContentMaterial {
  return data.content_type === 5;
}

export function IsItemDetail(data: IContent): data is IContentItem {
  return data.content_type === 6;
}

// community_id: 4
// content_id: 7
// content_type: 3
// cover_img: "https://wdzg-file-pre.oss-cn-hangzhou.aliyuncs.com/open_file20191231205002718000105360759.jpg"
// description: "课程"
// main_title: "测试课程aa"
// pv: 0
