export function initEnv() {
  if (!window.lib) {
    window.lib = {};
  }
  if (navigator.userAgent.indexOf('Mockuai') >= 0) {
    window.lib.env = 'mockuai';
  } else {
    window.lib.env = 'browser';
  }
  return window.lib.env;
}
