
  import { defineComponent, onMounted, ref } from 'vue';
  import type { Ref } from 'vue';
  import { useRoute } from 'vue-router';
  import MkTabs from '@/mk-view/tabs';
  import MkTab from '@/mk-view/tab';
  import storage from '@/common/storage';
  import CustomVideo from '@/components/video.vue';
  import { getCourseDetail, getQuestionList, Author, Course, Question } from '@/service';
  import { trackEvent } from '@/plugins/track/events';
  import VideoAuthorIntro from '../components/video-author-intro.vue';
  import { ICourseware } from '../components/types';
  // import QuestionList from '../components/question-list.vue';
  // import QuestionAction from '../components/question-action.vue';
  import Courseware from '../components/courseware.vue';
  import OpenVip from '../components/open-vip.vue';
  import OpenFormal from '../components/open-formal.vue';

  export default defineComponent({
    components: {
      MkTabs,
      MkTab,
      CustomVideo,
      VideoAuthorIntro,
      // QuestionList,
      // QuestionAction,
      Courseware,
      OpenVip,
      OpenFormal,
    },
    props: {},
    setup() {
      const route = useRoute();
      const isVip = ref(true);
      const isSVip = ref(true);
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';
      const loadingCourse = ref(true);
      const courseDetail: Ref<Course> = ref(new Course());
      const videoIntro: Ref<any> = ref({
        pv: 0,
        title: '',
        description: '',
        cover_img: '',
        course_type: 1,
      });

      const activedCoursewareList: Ref<ICourseware[]> = ref([]);

      const author = ref({});

      const loadCourseDetail = async () => {
        loadingCourse.value = true;

        const course = await getCourseDetail({
          id,
          community_id,
        });

        courseDetail.value = course.course_detail;

        document.title = course.course_detail.course_name;

        videoIntro.value = {
          pv: course.pv || 0,
          title: courseDetail.value.course_name,
          description: courseDetail.value.info,
          cover_img: course.cover_img || '',
          course_type: courseDetail.value.course_type,
        };

        activedCoursewareList.value = courseDetail.value.section_list.map((item) => {
          return {
            id: item.id,
            url: item.image_url,
          };
        });

        author.value = new Author(courseDetail.value);

        loadingCourse.value = false;
      };

      const questionList: Ref<Question[]> = ref([]);

      const loadQuestionList = async (course_id: number) => {
        const { question_list } = await getQuestionList({
          course_id,
          offset: 0,
          count: 100,
        });

        questionList.value = question_list.map((item) => {
          try {
            item.content = JSON.parse(item.content);
          } catch (e) {
            console.log(e);
          }
          return item;
        });
      };

      const onClickTab = (tab: any) => {
        if (tab === 1) {
          trackEvent('2244', route, {});
        }
        if (tab === 2) {
          trackEvent('2245', route, {});
        }
      };
      onMounted(async () => {
        if (route.query.token) {
          storage.set('access_token', route.query.token);
        }
        if (route.query.is_vip) {
          isVip.value = !!Number(route.query.is_vip);
        } else {
          isVip.value = true;
        }
        if (route.query.is_svip) {
          isSVip.value = !!Number(route.query.is_svip);
        } else {
          isSVip.value = true;
        }
        await loadCourseDetail();
        await loadQuestionList(courseDetail.value.course_id);
      });

      const onSendSuccessQuestion = () => {
        loadQuestionList(courseDetail.value.course_id);
      };

      return {
        onClickTab,
        author,
        videoIntro,
        courseDetail,
        activedCoursewareList,
        onSendSuccessQuestion,
        questionList,
        loadingCourse,
        isVip,
        isSVip,
      };
    },
  });
