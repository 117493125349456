export interface IQuestion {
  id: number;
  content: string;
  answer_list: Answer[];
}

export class Question implements IQuestion {
  id = 0;

  content = '';

  answer_list: Answer[] = [];

  constructor(data?: IQuestion) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.content = data.content;
    this.answer_list = data.answer_list;
  }
}

export interface IAnswer {
  id: number;
  content: string;
}

export class Answer implements IAnswer {
  id = 0;

  content = '';

  constructor(data?: IAnswer) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.content = data.content;
  }
}
