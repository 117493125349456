import { createApp } from 'vue';
import VConsole from 'vconsole';
import VueTrack from '@mk/v-track';
import { initEnv } from '@/plugins/environment';
import trackEvents, { pageParams } from '@/plugins/track/events';
import trackAction from '@/plugins/track/action';
import { getUserInfo } from '@/service/user';
import type { IUser } from '@/service/user/model';
import storage from '@/common/storage';
import App from './App.vue';
import router from './router';
import bridge from './plugins/bridge';

initEnv();

const app = createApp(App);

if (process.env.VUE_APP_ENV !== 'online') {
  // eslint-disable-next-line no-unused-vars
  const vConsole = new VConsole();
}

if (window.lib.env === 'mockuai') {
  bridge().then(async (res: any) => {
    const userInfo = await res.api.getUserInfo();
    if (userInfo && userInfo.userId) {
      storage.set('user', userInfo);
      await initTrack();
    } else {
      getUserInfo().then(async (user: IUser) => {
        storage.set('user', user);
        await initTrack();
      });
    }

    app.use(router).mount('#app');
    app.use(VueTrack, {
      router,
      trackEvents,
    });
  });
} else {
  getUserInfo().then(async (user: IUser) => {
    storage.set('user', user);
    await initTrack();
  });
  app.use(router).mount('#app');
  app.use(VueTrack, {
    router,
    trackEvents,
  });
}

async function initTrack() {
  // const trackerInfo = await res.api.getEventTrackerParams();
  let PAGE_ENTER_TIME: number;
  let currentRouter = router.currentRoute.value;
  let lastPageId: any = '';
  let hideMark: any;

  trackAction(
    'p_show',
    pageParams('p_show', currentRouter, '', {
      views_number: Math.round(Math.random() * 50),
      // short_link: trackerInfo.short_link || '',
      // last_page_id: lastPageId,
    }),
  );
  router.beforeEach(async (to, from, next) => {
    PAGE_ENTER_TIME = Date.now();
    currentRouter = to;

    lastPageId = from.meta.id || '';

    trackAction(
      'p_show',
      pageParams('p_show', currentRouter, '', {
        views_number: Math.round(Math.random() * 50),
        // short_link: trackerInfo.short_link || '',
        last_page_id: lastPageId,
      }),
    );
    next();
  });
  const UpPageHide = (mark: any) => {
    if (hideMark && hideMark === mark) {
      return;
    }
    hideMark = mark;
    trackAction('p_hide', pageParams('p_hide', currentRouter, (Date.now() - PAGE_ENTER_TIME).toString(), {}));
  };
  router.afterEach(() => {
    UpPageHide(PAGE_ENTER_TIME);
  });
  window.addEventListener('pagehide', () => {
    UpPageHide(PAGE_ENTER_TIME);
  });
  window.addEventListener('beforeunload', () => {
    UpPageHide(PAGE_ENTER_TIME);
  });
}
