/**
 *  APP_KEY: 后端给定
 *  APP_PWD: 后端给定
 *  API_URL: 请求域名
 *  H5_URL: 审核H5域名
 */
const config = {
  // 联调环境
  dev: {
    APP_KEY: '565c9b08223f2243257a6b4822320b8c',
    APP_PWD: '72df62e683f84d25d8c6303a93316bab',
    API_URL: 'http://apidev001.mockuai.com',
  },
  mock: {
    APP_KEY: '565c9b08223f2243257a6b4822320b8c',
    APP_PWD: '72df62e683f84d25d8c6303a93316bab',
    API_URL: 'http://yapi.mockuai.com/mock/1176',
  },
  // 预发环境
  wapa: {
    APP_KEY: '565c9b08223f2243257a6b4822320b8c',
    APP_PWD: '72df62e683f84d25d8c6303a93316bab',
    API_URL: 'https://apiwapa.mockuai.com',
  },
  // beta
  beta: {
    APP_KEY: '0f1586ba7551e524423bb39c1f45d3f6',
    APP_PWD: 'cb97fae3974805c5331a4f9e5036efeb',
    API_URL: 'https://mop-beta.mockuai.com',
  },
  // 线上
  online: {
    APP_KEY: '0f1586ba7551e524423bb39c1f45d3f6',
    APP_PWD: 'cb97fae3974805c5331a4f9e5036efeb',
    API_URL: 'https://api.mockuai.com',
  },
};

export default config;