<template>
  <div class="page course-series">
    <div class="custom-video">
      <custom-video
        v-if="isSVip || (seriesDetail.course_type === 1 && isVip)"
        :src="activedCourse.cloud_url"
        @ended="onEnded"
      ></custom-video>
      <img v-else class="custom-video-cover" :src="videoIntro.cover_img" />
    </div>
    <mk-tabs v-if="!activedCoursewareList && !activedCoursewareList.length" @change="onClickTab">
      <mk-tab class="tab-item" :name="1" title="相关课程">
        <video-author-intro class="video-author-intro" :video-intro="videoIntro" :author="author"></video-author-intro>
        <series-course-list
          v-if="isSVip || (seriesDetail.course_type === 1 && isVip)"
          :series-course-list="seriesCourseList"
          :actived-course="activedCourse"
          @activeCourse="onActiveCourse"
          @showSeriesCourseListDetail="onShowSeriesCourseListDetail"
        ></series-course-list>
        <!-- <question-list
          v-if="isSVip || (seriesDetail.course_type === 1 && isVip)"
          :question-list="questionList"
        ></question-list> -->
        <span v-if="isSVip || (seriesDetail.course_type === 1 && isVip)"></span>
        <open-vip v-else-if="seriesDetail.course_type === 2 && !isSVip"></open-vip>
        <open-formal v-else></open-formal>
      </mk-tab>
      <mk-tab class="tab-item" :disabled="!isVip" :name="2" title="相关课件">
        <courseware :courseware-list="activedCoursewareList"></courseware>
      </mk-tab>
    </mk-tabs>
    <div v-else>
      <video-author-intro class="video-author-intro" :video-intro="videoIntro" :author="author"></video-author-intro>
      <series-course-list
        v-if="isSVip || (seriesDetail.course_type === 1 && isVip)"
        :series-course-list="seriesCourseList"
        :actived-course="activedCourse"
        @activeCourse="onActiveCourse"
        @showSeriesCourseListDetail="onShowSeriesCourseListDetail"
      ></series-course-list>
      <!-- <question-list
        v-if="isSVip || (seriesDetail.course_type === 1 && isVip)"
        :question-list="questionList"
      ></question-list> -->
      <span v-if="isSVip || (seriesDetail.course_type === 1 && isVip)"></span>
      <open-vip v-else-if="seriesDetail.course_type === 2 && !isSVip"></open-vip>
      <open-formal v-else></open-formal>
    </div>
    <series-course-list-detail
      v-model:visible="seriesCourseListDetailVisible"
      :series-course-list="seriesCourseList"
      :actived-course="activedCourse"
      @activeCourse="onActiveCourse"
      @hideSeriesCourseListDetail="onHideSeriesCourseListDetail"
    ></series-course-list-detail>
    <!-- <div v-if="isSVip || (seriesDetail.course_type === 1 && isVip)" class="footer--fix">
      <question-action :course-id="activedCourse.course_id" @sendSuccess="onSendSuccessQuestion"></question-action>
    </div> -->
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import type { Ref } from 'vue';
  import { useRoute } from 'vue-router';
  import MkTabs from '@/mk-view/tabs';
  import MkTab from '@/mk-view/tab';
  import CustomVideo from '@/components/video.vue';
  import { getSeriesDetail, getQuestionList, Series, Author, Course } from '@/service';
  import { trackEvent } from '@/plugins/track/events';
  import storage from '@/common/storage';
  import VideoAuthorIntro from '../components/video-author-intro.vue';
  import { ICourseware } from '../components/types';
  import SeriesCourseList from './components/series-course-list.vue';
  import SeriesCourseListDetail from './components/series-course-list-detail.vue';
  // import QuestionList from '../components/question-list.vue';
  // import QuestionAction from '../components/question-action.vue';
  import Courseware from '../components/courseware.vue';
  import OpenVip from '../components/open-vip.vue';
  import OpenFormal from '../components/open-formal.vue';

  export default defineComponent({
    components: {
      MkTabs,
      MkTab,
      VideoAuthorIntro,
      CustomVideo,
      SeriesCourseList,
      SeriesCourseListDetail,
      // QuestionList,
      // QuestionAction,
      Courseware,
      OpenVip,
      OpenFormal,
    },
    props: {},
    setup() {
      const route = useRoute();
      const isVip = ref(true);
      const isSVip = ref(true);
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';
      const sub_course_id = route.query.sub_course_id ? Number(route.query.sub_course_id) : '';
      const seriesDetail: Ref<Series> = ref(new Series());
      const videoIntro: Ref<any> = ref({
        pv: 0,
        title: '',
        description: '',
        cover_img: '',
        course_type: 1,
      });
      const author = ref({});
      const seriesCourseList: Ref<Course[]> = ref([]);
      const activedCourse: Ref<Course> = ref(new Course());
      const activedCoursewareList: Ref<ICourseware[]> = ref([]);

      // 激活课程
      const onActiveCourse = async (course: Course) => {
        if (activedCourse.value.course_id === course.course_id) {
          return;
        }
        trackEvent(
          '2247',
          route,
          {},
          {
            course_id: course.course_id,
          },
        );
        document.title = course.course_name;
        activedCourse.value = course;
        activedCoursewareList.value = course.section_list.map((item) => {
          return {
            id: item.id,
            url: item.image_url,
          };
        });
        await loadQuestionList();
      };

      const onEnded = () => {
        const index = seriesCourseList.value.findIndex((item) => {
          return item.course_id === activedCourse.value.course_id;
        });
        if (index >= seriesCourseList.value.length - 1) {
          return;
        }
        onActiveCourse(seriesCourseList.value[index + 1]);
      };

      const question = ref('');
      const questionList = ref([{}]);
      const loadQuestionList = async () => {
        const { question_list } = await getQuestionList({
          course_id: activedCourse.value.course_id,
          offset: 0,
          count: 100,
        });

        questionList.value = question_list.map((item) => {
          try {
            item.content = JSON.parse(item.content);
          } catch (e) {
            console.log(e);
          }
          return item;
        });
      };
      const onSendSuccessQuestion = () => {
        loadQuestionList();
      };

      const seriesCourseListDetailVisible = ref(false);

      const onShowSeriesCourseListDetail = () => {
        trackEvent('2246', route, {});
        seriesCourseListDetailVisible.value = true;
      };

      const onHideSeriesCourseListDetail = () => {
        seriesCourseListDetailVisible.value = false;
      };

      const onClickTab = (tab: any) => {
        if (tab === 1) {
          trackEvent('2244', route, {});
        }
        if (tab === 2) {
          trackEvent('2245', route, {});
        }
      };
      onMounted(async () => {
        if (route.query.token) {
          storage.set('access_token', route.query.token);
        }
        if (route.query.is_vip) {
          isVip.value = !!Number(route.query.is_vip);
        } else {
          isVip.value = true;
        }
        if (route.query.is_svip) {
          isSVip.value = !!Number(route.query.is_svip);
        } else {
          isSVip.value = true;
        }
        const series = await getSeriesDetail({
          id,
          community_id,
        });

        seriesDetail.value = series.course_detail;

        videoIntro.value = {
          pv: series.pv || 0,
          title: seriesDetail.value.course_name,
          description: seriesDetail.value.info,
          cover_img: series.cover_img,
          course_type: seriesDetail.value.course_type,
        };

        seriesCourseList.value = seriesDetail.value.sub_course_detail_list;

        seriesCourseList.value.forEach((item: any) => {
          item.course_id = item.id;
          item.course_name = item.name;
        });

        author.value = new Author(seriesDetail.value);

        if (!sub_course_id) {
          onActiveCourse(seriesCourseList.value[0]);
        } else {
          const course = seriesCourseList.value.find((item) => {
            return item.course_id === sub_course_id;
          });
          if (course) {
            onActiveCourse(course);
          }
        }
      });

      return {
        isVip,
        isSVip,
        onClickTab,
        onEnded,
        seriesDetail,
        videoIntro,
        author,
        seriesCourseList,
        activedCourse,
        activedCoursewareList,
        seriesCourseListDetailVisible,
        question,
        onActiveCourse,
        onShowSeriesCourseListDetail,
        onHideSeriesCourseListDetail,
        questionList,
        onSendSuccessQuestion,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .custom-video {
    height: 56.25vw;
  }
  .custom-video-cover {
    display: block;
    width: 100%;
    margin: 0 auto;
    object-fit: cover;
  }
  .tab-item {
    height: calc(100vh - 56.25vw - #{px(64)});
    overflow-y: auto;
  }
  .video-author-intro {
    margin-bottom: $box-space-medium;
  }
  .courseware {
    padding: px(10);
    img {
      margin-bottom: px(8);
      border-radius: px(4);
    }
  }
  .footer--fix {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    width: 100%;
  }
</style>
