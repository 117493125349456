<template>
  <div id="wrapper"></div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, toRefs, watch, ref, nextTick } from 'vue';
  import ChimeeMobilePlayer from '@mk/chimee-mobile-player';

  export default defineComponent({
    emits: ['ended'],
    props: {
      src: {
        type: String,
        default: '',
      },
    },
    setup(props, { emit }) {
      const { src } = toRefs(props);
      const { env } = window.lib;
      const networkStatus = ref('');
      let videoSetTimeout: any;

      let player: any = null;

      const videoPlay = (_player: any) => {
        if (videoSetTimeout) {
          clearTimeout(videoSetTimeout);
          videoSetTimeout = null;
        }
        videoSetTimeout = setTimeout(() => {
          if (env === 'mockuai') {
            // 根据网络状态决定是否播放视频
            if (networkStatus.value === 'wifi') {
              _player.play();
            } else {
              _player.pause();
            }
          }
          if (env === 'browser') {
            _player.play();
          }
          clearTimeout(videoSetTimeout);
          videoSetTimeout = null;
        }, 1000);
      };

      const initVideo = async (_src: any) => {
        player = new ChimeeMobilePlayer({
          wrapper: '#wrapper', // video dom容器
          _src,
          autoplay: true,
          controls: true,
          playsInline: true,
          preload: true,
          x5VideoPlayerFullscreen: true,
          x5VideoOrientation: true,
          xWebkitAirplay: true,
          x5VideoPlayerType: 'h5-page',
          muted: false,
        });
        player.ready.then(() => {
          videoPlay(player);
          window.document
            .querySelector('#wrapper')
            ?.querySelector('video')
            ?.addEventListener(
              'ended',
              () => {
                emit('ended');
              },
              false,
            );
        });
      };

      onMounted(async () => {
        if (env === 'mockuai') {
          const { bridge, api } = window.lib.bridge;
          networkStatus.value = (await api.getNetworkStatus()).status;
          api.startNetworkStatusMonitoring(); /* status (unknown/未知网络, notReachable/没有网络, wwan/手机网络, wifi/无线网络) */
          bridge.register(
            'networkStatusChange',
            (response: { status: 'unknown' | 'notReachable' | 'wwan' | 'wifi' }) => {
              networkStatus.value = response.status;
            },
          );
        }
        initVideo(src.value);
      });

      const changeVideo = (_src: any) => {
        player.load(_src);
        player.ready.then(() => {
          videoPlay(player);
        });
      };

      watch(src, () => {
        changeVideo(src.value);
      });

      watch(networkStatus, () => {
        videoPlay(player);
      });
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  #wrapper {
    width: 100%;
    height: 100%;
    background-color: #000;
  }
  /deep/container {
    position: relative;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    display: block;
    width: 100%;
    height: 100%;
    background: #000;
    outline: none;

    video {
      display: block;
      width: 100%;
      height: 100%;
      background: #000;
      outline: none;
    }
    video:focus {
      outline: none;
    }
    .chimee-flex-component svg:hover g,
    .chimee-flex-component svg g {
      fill: #fff;
      stroke: #fff;
    }
    chimee-clarity-list,
    chimee-control-state-pause,
    chimee-control-state-play,
    chimee-screen-full,
    chimee-screen-small {
      display: none;
    }
    chimee-control.full chimee-screen-full,
    chimee-control.pause chimee-control-state-pause,
    chimee-control.play chimee-control-state-play,
    chimee-control.small chimee-screen-small {
      display: inline-block;
      width: 1.4em;
      height: 100%;
      color: white;
    }
    chimee-control {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 4em;
      overflow: hidden;
      font-size: 10px;
      font-family: Roboto, Arial, Helvetica, sans-serif;
      transition: visibility 0.5s ease;
      user-select: none;
    }
    chimee-control:focus {
      outline: none;
    }
    chimee-control-wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 4em;
      line-height: 4em;
      background: rgba(0, 0, 0, 0.5);
      transition: bottom 0.5s ease;
      pointer-events: auto;
    }
    .chimee-flex-component {
      flex-grow: 0;
      order: 1;
      height: 4em;
      cursor: pointer;
    }
    .chimee-flex-component svg {
      width: 1.8em;
      height: 1.8em;
      vertical-align: middle;
    }
    chimee-control-state.chimee-flex-component {
      flex-basis: 3em;
      margin-right: 1em;
      text-align: right;
    }
    chimee-control-state .left,
    chimee-control-state .right {
      transition: d 0.2s ease-in-out;
    }
    chimee-current-time.chimee-flex-component,
    chimee-total-time.chimee-flex-component {
      color: #fff;
      font-weight: 400;
      font-size: 11px;
      white-space: nowrap;
      text-align: center;
    }
    chimee-current-time + chimee-total-time::before {
      padding: 4px;
      content: '/';
    }
    chimee-progressbar.chimee-flex-component {
      position: relative;
      flex-grow: 1;
      margin: 0 1.5em;
      pointer-events: none;
    }
    .chimee-progressbar-line {
      position: absolute;
      top: 2em;
      left: 0;
      display: inline-block;
      height: 2px;
      border-radius: 2px;
    }
    chimee-progressbar-bg {
      width: 100%;
      background: white;
      opacity: 0.4;
    }
    chimee-progressbar-buffer {
      width: 0;
      // background: white;
      // opacity: 0.4;
    }
    chimee-progressbar-all {
      background: white;
      opacity: 0.9;
    }
    chimee-progressbar-ball {
      position: absolute;
      top: -0.6em;
      right: -1em;
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      background: #fff;
      border-radius: 1.4em;
      content: '';
      pointer-events: none;
    }
    chimee-screen.chimee-flex-component {
      flex-basis: 3em;
      margin-left: 1em;
      text-align: left;
    }
    video::-webkit-media-controls-start-playback-button {
      display: none;
    }
    chimee-state svg {
      width: 100%;
      height: 100%;
    }
    @keyframes a {
      0% {
        transform: rotate(0);
      }
      to {
        transform: rotate(1turn);
      }
    }
    chimee-state {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 24px;
    }
    chimee-state-error,
    chimee-state-loading,
    chimee-state-pause,
    chimee-state-play {
      position: absolute;
      top: 50%;
      left: 50%;
      display: none;
      transform: translate(-50%, -50%);
    }
    chimee-state-pause,
    chimee-state-play {
      box-sizing: initial;
      width: 2em;
      height: 2em;
    }
    chimee-state-loading {
      width: 2em;
      height: 2em;
      margin: -1em;
      transform: none;
      animation: 0.9s a linear infinite;
    }
    chimee-state-error {
      z-index: 1;
      display: none;
      color: #ffcf00;
      font-weight: 100;
      font-size: 16px;
      text-shadow: 0 0 3px red;
    }
    chimee-state.error chimee-state-error,
    chimee-state.loading chimee-state-loading,
    chimee-state.play chimee-state-play {
      display: inline-block;
    }
  }
</style>
