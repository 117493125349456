<template>
  <div class="author-intro" :class="'author-intro--' + theme">
    <mk-skeleton
      :loading="loading"
      :row="3"
      type="avatar"
      avatar-size="52px"
      :avatar-style="{
        iconSize: 26,
      }"
      :row-style="[
        {
          width: 120,
          height: 16,
          marginBottom: 4,
        },
        {
          width: '100%',
          height: 14,
          marginBottom: 4,
        },
        {
          width: 140,
          height: 14,
        },
      ]"
    >
      <div class="avatar">
        <img class="image-avatar image-community" :src="author.head_url" alt="" />
      </div>
      <div>
        <div class="title">
          {{ author.author }}
        </div>
        <div class="content content--ellipsis2">
          {{ author.title }}
        </div>
      </div>
    </mk-skeleton>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import MkSkeleton from '@/mk-view/skeleton';
  import { Author } from '@/service';

  export default defineComponent({
    components: { MkSkeleton },
    props: {
      author: {
        type: Object,
        default() {
          return new Author();
        },
      },
      loading: {
        type: Boolean,
        default: false,
      },
      theme: {
        type: String,
        default: 'default',
      },
    },
    setup(props) {},
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .author-intro--default {
    display: flex;
    width: 100%;
    padding: $box-space-xl $box-space-large;
    background-color: $color-bg-light;
    .mk-skeleton {
      width: 100%;
    }
    .avatar {
      flex-shrink: 0;
      width: 52px;
      height: 52px;
      margin-right: $box-space-medium;
      img {
        @include image-cover();
      }
    }
    .title {
      @include init-font(14, 16, $color-text-highlight);

      flex-shrink: 1;
      margin-bottom: $box-space-mini;
      font-weight: bold;
    }
    .content {
      @include init-font(12, 16, $color-text-primary);

      // height: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      letter-spacing: 1px;

      &--ellipsis2 {
        @include ellipsis(2);
      }
    }
  }

  .author-intro--header {
    position: relative;
    width: 100%;
    padding: px(28) px(28) px(16) px(28);
    background-color: $color-bg-light;
    .mk-skeleton {
      width: 100%;
    }
    .avatar {
      position: absolute;
      top: px(-30);
      left: px(28);
      flex-shrink: 0;
      width: px(72);
      height: px(72);
      margin-right: $box-space-medium;
      border: 3px solid white;
      border-radius: 50%;

      img {
        @include image-cover();
      }
    }
    .title {
      @include init-font(20, 20, $color-text-highlight);

      flex-shrink: 1;
      margin-top: px(30);
      margin-bottom: px(8);
      font-weight: bold;
    }
    .content {
      @include init-font(12, 18, $color-text-primary);

      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
</style>
