import { Author } from './Author.model';

/* 发圈素材 */
interface IGoods extends Author {
  item_video_url?: string;
  item_img: string[];
  item_desc: string;
  item_id: number;
}

export class Goods extends Author implements IGoods {
  item_video_url?: string;

  item_img: string[] = [];

  item_desc = '';

  item_id = 0;

  constructor(data?: IGoods) {
    super(data);
    if (data) {
      if (data.item_video_url) this.item_video_url = data.item_video_url;
      this.item_img = data.item_img;
      this.item_desc = data.item_desc;
      this.item_id = data.item_id;
    }
  }
}
