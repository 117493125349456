import { Bridge, IBridge, bridgeApi } from '@mk/bridge';

const _bridge = new Bridge('pk');

let api: any = {};
let bridge: any = {};

export default function BridgeInit() {
  return new Promise((_resolve) => {
    _bridge.ready((nApi: bridgeApi, nBridge: IBridge, env: string) => {
      if (env === 'pk') {
        api = nApi;
        bridge = nBridge;

        api.navigateBack = () => {
          nBridge.callHandler('navigateBack');
          nBridge.callHandler('goBack'); // 老版本ios命名和现有方法不一致
        };

        api.communityGoodsForward = (params: { goodsId: number; title: string; images: string }) => {
          return new Promise((resolve) => {
            nBridge.callHandler('communityGoodsForward', params, (response: any) => {
              resolve(response);
            });
          });
        };

        api.communityMaterialShare = (params: { title: string; images: string }) => {
          return new Promise((resolve) => {
            nBridge.callHandler('communityMaterialShare', params, (response: any) => {
              resolve(response);
            });
          });
        };

        api.getNetworkStatus = () => {
          return new Promise((resolve) => {
            nBridge.callHandler('getNetworkStatus', {}, (response: any) => {
              resolve(response.data);
            });
          });
        };

        api.getUserInfo = () => {
          return new Promise((resolve) => {
            nBridge.callHandler('getUserInfo', {}, (response: any) => {
              resolve(response.data);
            });
          });
        };

        api.getAccessToken = () => {
          return new Promise((resolve) => {
            nBridge.callHandler('getAccessToken', {}, (response: any) => {
              resolve(response.data);
            });
          });
        };

        api.getEventTrackerParams = () => {
          return new Promise((resolve) => {
            nBridge.callHandler('communityEventTrackerParameters', {}, (response: any) => {
              resolve(response.data);
            });
          });
        };

        // 开启网络监听
        api.startNetworkStatusMonitoring = () => {
          nBridge.callHandler('startNetworkStatusMonitoring');
        };

        // 展示/隐藏页面title
        api.navigationBarConfig = (params: { hiddenNavigationBar: boolean; backButtonStyle?: number }) => {
          nBridge.callHandler('navigationBarConfig', params);
        };

        if (!window.lib) {
          window.lib = {};
        }
        window.lib.bridge = {
          api,
          bridge,
        };
        _resolve({
          api,
          bridge,
        });
      }
    });
  });
}
