export class Author {
  author = '';

  author_id = 0;

  head_url = '';

  title = '';

  constructor(data?: IAuthor) {
    if (!data) {
      return;
    }
    this.author = data.author;
    this.author_id = data.author_id;
    this.head_url = data.head_url;
    this.title = data.title;
  }
}

export interface IAuthor {
  author: string;
  author_id: number;
  head_url: string;
  title: string;
}
