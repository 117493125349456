import { createBEM } from './bem';
import { createComponent } from './component';

export function createNamespace(name: string) {
  name = `mk-${ name}`;
  return [
    createComponent(name),
    createBEM(name),
  ] as const;
}
