<template>
  <div class="video-intro">
    <mk-skeleton
      :loading="loading"
      :row="3"
      :row-style="[
        {
          width: 194,
          height: 22,
        },
        {
          width: 140,
          height: 15,
        },
        {
          width: '100%',
          height: 89,
        },
      ]"
    >
      <div class="title">
        <span v-if="videoIntro.course_type === 2" class="text">vip</span>
        {{ videoIntro.title }}
      </div>
      <div v-if="videoIntro.pv" class="cut">
        <i class="iconfont iconview"></i>
        浏览 {{ videoIntro.pv }}
      </div>
      <div class="content">
        <mk-ellipsis :text="videoIntro.description" @showMore="onShowMode"></mk-ellipsis>
      </div>
    </mk-skeleton>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import MkEllipsis from '@/mk-view/ellipsis';
  import MkSkeleton from '@/mk-view/skeleton';
  import { trackEvent } from '@/plugins/track/events';

  export default defineComponent({
    components: { MkEllipsis, MkSkeleton },
    props: {
      videoIntro: {
        type: Object,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const ellipsis = ref(true);
      const route = useRoute();
      const onShowMode = () => {
        trackEvent('2243', route, {});
      };
      return {
        ellipsis,
        onShowMode,
      };
    },
  });
</script>

<style lang="scss">
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .video-intro {
    padding: $box-space-xl $box-space-large px(14) $box-space-large;
    background-color: $color-bg-light;

    .title {
      @include init-font(17, 24, #333);

      margin-bottom: px(10);
      font-weight: bold;

      .text {
        position: relative;
        top: px(-2);
        display: inline-block;
        width: px(24);
        height: px(16);
        margin-right: px(4);
        color: #f3d9bc;
        font-weight: bold;
        font-size: px(10);
        font-family: PingFangSC-Semibold, PingFang SC;
        line-height: px(14);
        text-align: center;
        background: linear-gradient(135deg, #3f3f3f 0%, #262626 100%);
        border-radius: px(3);
      }
    }

    .cut {
      @include init-font(12, 10, #999);

      height: px(11);
      margin-bottom: px(14);
      font-family: PingFangSC-Regular, PingFang SC;

      i {
        font-size: px(10);
      }
    }

    .content {
      @include init-font(14, 18, #333);

      letter-spacing: 1px;

      &--ellipsis2 {
        @include ellipsis(2);

        height: 36px;
      }
    }
  }
</style>
