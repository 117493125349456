
  import { defineComponent, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import MkEllipsis from '@/mk-view/ellipsis';
  import MkSkeleton from '@/mk-view/skeleton';
  import { trackEvent } from '@/plugins/track/events';

  export default defineComponent({
    components: { MkEllipsis, MkSkeleton },
    props: {
      videoIntro: {
        type: Object,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const ellipsis = ref(true);
      const route = useRoute();
      const onShowMode = () => {
        trackEvent('2243', route, {});
      };
      return {
        ellipsis,
        onShowMode,
      };
    },
  });
