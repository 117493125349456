<template>
  <div class="content">
    <img class="vip-bg" src="https://mktv-in-cdn.mockuai.com/16799059631339549.png" />
    <div class="vip-info">
      <div class="vip-info-title">抱歉，您还未开通会员</div>
      <div class="vip-info-content">成为VIP可参与返现任务，学习精品课程，所有算力还都打5折哦！</div>
      <div class="vip-info-btn" @click="openVip()">开通VIP</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  declare const window: any;

  export default defineComponent({
    props: {
      questionList: {
        type: Object,
      },
    },
    setup() {
      const openVip = () => {
        const ua = navigator.userAgent.toLowerCase();
        const isTTWebView = ua.includes('toutiaomicroapp');
        const isWeixin = ua.includes('micromessenger');

        if (isTTWebView) {
          window.tt.miniProgram.navigateTo({
            url: '/pages/module/open/index',
          });
        } else if (isWeixin) {
          window.wx.miniProgram.navigateTo({
            url: '/pages/module/open/index',
          });
        } else {
          window.ks.navigateTo({
            url: '/pages/module/open/index',
          });
        }
      };
      return { openVip };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/theme/theme.scss';
  @import '@/mk-view/style/helpers/helpers.scss';

  .content {
    position: relative;
    margin: px(8) 0 0;

    .vip-bg {
      width: 100%;
      border-radius: 0 0 px(4) px(4);
    }

    .vip-info {
      position: absolute;
      top: 50%;
      left: 50%;
      width: px(250);
      height: px(128);
      margin: px(-64) 0 0 px(-125);
      background: url('https://mktv-in-cdn.mockuai.com/16778347021291803.png') no-repeat center;
      background-size: cover;
      border-radius: px(12);

      .vip-info-title {
        padding: px(16) px(16) px(4);
        color: #fff;
        font-weight: 500;
        font-size: px(14);
        line-height: px(20);
      }

      .vip-info-content {
        padding: px(2) px(16) px(14);
        color: rgba(255, 255, 255, 0.8);
        font-weight: 400;
        font-size: px(12);
        line-height: px(16);
      }

      .vip-info-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: px(68);
        height: px(28);
        margin-left: px(15);
        color: #875320;
        font-weight: 500;
        font-size: px(12);
        background: #f3d4b2;
        border-radius: px(20);
      }
    }
  }
</style>
