import storage from '@/common/storage';
import Request from '@/common/mk-request/src';
import md5 from 'crypto-js/md5';
import { initEnv } from '@/plugins/environment';
import config from './config';

initEnv();

const baseUrl = (config as any)[process.env.VUE_APP_ENV].API_URL;
const appKey = (config as any)[process.env.VUE_APP_ENV].APP_KEY;
const appPwd = (config as any)[process.env.VUE_APP_ENV].APP_PWD;

const getSessionToken = (): string => {
  return storage.get('session_token');
};
const setSessionToken = (sessionToken: string) => {
  storage.set('session_token', sessionToken);
};
const getAccessToken = (): string => {
  return storage.get('access_token');
};
const setAccessToken = (accessToken: string) => {
  storage.set('access_token', accessToken);
};
const getRefreshToken = (): string => {
  return storage.get('refresh_token');
};
const setRefreshToken = (refreshToken: string) => {
  storage.set('refresh_token', refreshToken);
};
const isLogin = (): boolean => {
  return !!getRefreshToken();
};

const requestConfig: any = {
  baseUrl,
  appKey,
  appPwd,
  needSessionToken: true,
  getSessionToken,
  setSessionToken,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  isLogin,
  logout: () => {
    storage.remove('access_token');
    storage.remove('refresh_token');
  },
  // getRefreshTokenFunc: () => {},
};

if (window.lib.env === 'mockuai') {
  let number = 0;
  requestConfig.getAccessTokenFunc = () => {
    window.lib.bridge.bridge.callHandler('getAccessToken', {}, (response: any) => {
      if (getAccessToken() !== response.data.access_token) {
        number = 0;
        setAccessToken(response.data.access_token);
        return;
      }
      setAccessToken('');
      number++;
      if (number > 10) {
        window.lib.bridge.bridge.callHandler('authorize', { scope: 'userInfo' }, (authorizeRes: any) => {
          console.log(authorizeRes);
        });
      }
    });
  };
  requestConfig.getRefreshTokenFunc = () => {
    window.lib.bridge.bridge.callHandler('getAccessToken', {}, (response: any) => {
      if (getAccessToken() !== response.data.access_token) {
        number = 0;
        setAccessToken(response.data.access_token);
        return;
      }
      setAccessToken('');
      number++;
      if (number > 10) {
        window.lib.bridge.bridge.callHandler('authorize', { scope: 'userInfo' }, (authorizeRes: any) => {
          console.log(authorizeRes);
        });
      }
    });
  };
}

const request = new Request(requestConfig);

request.getSessionTokenUrl = 'ec/a/login/session_token/get';
request.getAccessTokenUrl = 'ec/b/login/shop/pc/login';
request.getRefreshTokenUrl = 'ec/b/login/shop/pc/login';

if (window.lib.env !== 'mockuai') {
  if (process.env.VUE_APP_ENV === 'beta' || process.env.VUE_APP_ENV === 'online') {
    request.loginData = {
      role_type: '1',
      mobile: '18357876521',
      password: md5('224064').toString(),
    };
  } else {
    request.loginData = {
      role_type: '3',
      mobile: '18626895702',
      password: md5('mk123456').toString(),
    };
  }
}

export default request;
