import { createNamespace, getSizeStyle } from '../utils';
import { Network } from './Network';

const [createComponent, bem] = createNamespace('empty');

const PRESET_IMAGES: any = {
  error: 'https://mktv-in-cdn.mockuai.com/16160481496918902.png',
  default: 'https://mktv-in-cdn.mockuai.com/16160481541487059.png',
};

export default createComponent({
  props: {
    imageSize: [Number, String],
    description: String,
    image: {
      type: String,
      default: 'default',
    },
  },

  setup(props, { slots }) {
    const renderImage = () => {
      if (slots.image) {
        return slots.image();
      }

      let { image } = props;

      if (image === 'network') {
        return Network;
      }

      image = PRESET_IMAGES[image] || image;

      return <img src={image} />;
    };

    const renderDescription = () => {
      const description = slots.description ? slots.description() : props.description;

      if (description) {
        return <p class={bem('description')}>{description}</p>;
      }
    };

    const renderBottom = () => {
      if (slots.default) {
        return <div class={bem('bottom')}>{slots.default()}</div>;
      }
    };

    return () => (
      <div class={bem()}>
        <div class={bem('image')} style={getSizeStyle(props.imageSize)}>
          {renderImage()}
        </div>
        {renderDescription()}
        {renderBottom()}
      </div>
    );
  },
});
