<template>
  <div class="more-recommend">
    <div class="header">
      <div class="title">更多推荐</div>
    </div>
    <div class="content">
      <div v-for="item in nRecommendList" :key="item.content_id" class="recommend" @click="linkToRecommend(item)">
        <div class="recommend__content">
          <div class="recommend__title">{{ item.main_title }}</div>
          <div class="recommend__author">
            <img class="portrait" :src="item.author_head_logo" alt="" />
            作者： {{ item.author_name }}
          </div>
          <div class="recommend__info">
            <div class="duration">
              <i class="iconfont icontime"></i>
              {{ filterDuration(item.video_duration) }}
            </div>
            <div class="view">
              <i class="iconfont iconview"></i>
              {{ item.pv }}
            </div>
          </div>
        </div>
        <div class="recommend__cover">
          <img :src="item.cover_img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { useRouter, useRoute } from 'vue-router';
  import { AuthorCourseSeries } from '@/service';
  import { defineComponent, Ref, ref, toRef, watch } from 'vue';
  import Utils from '@mk/mk-utils';
  import { trackEvent } from '@/plugins/track/events';
  import storage from '@/common/storage';

  export default defineComponent({
    components: {},
    emits: [],
    props: {
      recommendList: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    setup(props) {
      const router = useRouter();
      const route = useRoute();
      const nRecommendList: Ref<any[]> = ref([]);
      const recommendList: Ref<any[]> = toRef(props, 'recommendList');

      const isReadRecommend = (id: number) => {
        if (!storage.get('readVideoList')) {
          return false;
        }
        const readVideoList = storage.get('readVideoList');

        return readVideoList.findIndex((item: number) => item === id) >= 0;
      };

      const refreshRecommendList = () => {
        const readList: any[] = [];
        nRecommendList.value = recommendList.value.reduce((pre: any[], item: any) => {
          if (!isReadRecommend(item.content_id)) {
            pre.push(item);
          } else {
            readList.push(item);
          }
          return pre;
        }, []);
        nRecommendList.value = [...nRecommendList.value, ...readList];
      };

      watch(recommendList, () => {
        refreshRecommendList();
      });

      const linkToRecommend = (item: AuthorCourseSeries) => {
        trackEvent('2249', route, {}, { content_id: item.content_id });

        switch (item.content_type) {
          case 2: {
            router.replace({
              path: '/video/video',
              query: {
                id: item.content_id,
                community_id: route.query.community_id,
              },
            });
            break;
          }
          case 3: {
            router.replace({
              path: '/video/course',
              query: {
                id: item.content_id,
                community_id: route.query.community_id,
              },
            });
            break;
          }
          case 4: {
            router.replace({
              path: '/video/course-series',
              query: {
                id: item.content_id,
                community_id: route.query.community_id,
              },
            });
            break;
          }
          default: {
            break;
          }
        }
      };

      const filterDuration = (value: number) => {
        return Utils.MKString.formatDuration(value, 'mm:ss');
      };

      return {
        linkToRecommend,
        filterDuration,
        nRecommendList,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .more-recommend {
    width: 100%;
    padding: 0 px(10);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: px(28);
      margin: px(10) 0;
      padding: 0 $box-space-large;
      .title {
        @include init-font(15, 16, $color-text-highlight);

        font-weight: bold;
      }
    }
    .content {
      width: 100%;
      .recommend {
        display: flex;
        margin-bottom: px(10);
        padding: px(12);
        background-color: $color-bg-light;
        border-radius: 8px;
        &__content {
          flex-shrink: 1;
          width: 100%;
          margin-right: px(35);
        }
        &__title {
          @include init-font(14, 16, $color-text-highlight);
          @include ellipsis(1);

          margin-bottom: px(8);
          font-weight: bold;
        }
        &__author {
          @include init-font(12, 12, $color-text-highlight);

          display: flex;
          align-items: center;
          margin-bottom: px(10);
          .portrait {
            width: px(20);
            height: px(20);
            margin-right: px(4);
            border-radius: 50%;
          }
        }
        &__info {
          @include init-font(10, 10, $color-text-secondary);

          display: flex;
          align-items: center;
          .duration {
            width: px(50);
            margin-right: px(16);
          }
          i {
            color: #bbb;
            font-size: px(10);
          }
        }
        &__cover {
          flex-shrink: 0;
          width: px(100);
          img {
            @include image-cover();

            border-radius: px(8);
          }
        }
      }
    }
  }
</style>
