<template>
  <div class="page">
    <div v-for="item in urlList" :key="item.name">
      <router-link :to="item.path">{{ item.name }}</router-link>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';

  const URL_List = [
    {
      path: '/author/lecturer?community_id=1&id=18',
      name: '作者介绍',
    },
    {
      path: '/content/material?id=382',
      name: '素材分享',
    },
    {
      path: '/content/article?id=170',
      name: '文章',
    },
    {
      path: '/goods/goods-popular?id=493',
      name: '爆款商品',
    },
    {
      path: '/video/video?id=6',
      name: '视频',
    },
    {
      path: '/video/course?id=2',
      name: '课程',
    },
    {
      path: '/video/course-series?id=231',
      name: '系列课程',
    },
    // {
    //   path: '/video/course-series?id=70&sub_course_id=175',
    //   name: '系列课程',
    // },
  ];
  export default defineComponent({
    props: {},
    setup() {
      const urlList = ref(URL_List);
      return {
        urlList,
      };
    },
  });
</script>
