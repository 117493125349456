<template>
  <div class="page course">
    <div class="custom-video">
      <custom-video
        v-if="isSVip || (courseDetail.course_type === 1 && isVip)"
        :src="courseDetail.cloud_url"
      ></custom-video>
      <img v-else class="custom-video-cover" :src="videoIntro.cover_img" />
    </div>
    <mk-tabs v-if="!activedCoursewareList && !activedCoursewareList.length" @click="onClickTab">
      <mk-tab class="tab-item" title="相关课程">
        <video-author-intro
          class="video-author-intro"
          :video-intro="videoIntro"
          :author="author"
          :loading="loadingCourse"
          :name="1"
        ></video-author-intro>
        <!-- <question-list
          v-if="isSVip || (courseDetail.course_type === 1 && isVip)"
          :question-list="questionList"
        ></question-list> -->
        <span v-if="isSVip || (courseDetail.course_type === 1 && isVip)"></span>
        <open-vip v-else-if="courseDetail.course_type === 2 && !isSVip"></open-vip>
        <open-formal v-else></open-formal>
      </mk-tab>
      <mk-tab :name="2" :disabled="!isVip" class="tab-item tab-item__light" title="相关课件">
        <courseware :courseware-list="activedCoursewareList"></courseware>
      </mk-tab>
    </mk-tabs>
    <div v-else>
      <video-author-intro
        class="video-author-intro"
        :video-intro="videoIntro"
        :author="author"
        :loading="loadingCourse"
        :name="1"
      ></video-author-intro>
      <!-- <question-list
        v-if="isSVip || (courseDetail.course_type === 1 && isVip)"
        :question-list="questionList"
      ></question-list> -->
      <span v-if="isSVip || (courseDetail.course_type === 1 && isVip)"></span>
      <open-vip v-else-if="courseDetail.course_type === 2 && !isSVip"></open-vip>
      <open-formal v-else></open-formal>
    </div>
    <!-- <div v-if="isSVip || (courseDetail.course_type === 1 && isVip)" class="footer--fix">
      <question-action :course-id="courseDetail.course_id" @sendSuccess="onSendSuccessQuestion"></question-action>
    </div> -->
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import type { Ref } from 'vue';
  import { useRoute } from 'vue-router';
  import MkTabs from '@/mk-view/tabs';
  import MkTab from '@/mk-view/tab';
  import storage from '@/common/storage';
  import CustomVideo from '@/components/video.vue';
  import { getCourseDetail, getQuestionList, Author, Course, Question } from '@/service';
  import { trackEvent } from '@/plugins/track/events';
  import VideoAuthorIntro from '../components/video-author-intro.vue';
  import { ICourseware } from '../components/types';
  // import QuestionList from '../components/question-list.vue';
  // import QuestionAction from '../components/question-action.vue';
  import Courseware from '../components/courseware.vue';
  import OpenVip from '../components/open-vip.vue';
  import OpenFormal from '../components/open-formal.vue';

  export default defineComponent({
    components: {
      MkTabs,
      MkTab,
      CustomVideo,
      VideoAuthorIntro,
      // QuestionList,
      // QuestionAction,
      Courseware,
      OpenVip,
      OpenFormal,
    },
    props: {},
    setup() {
      const route = useRoute();
      const isVip = ref(true);
      const isSVip = ref(true);
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';
      const loadingCourse = ref(true);
      const courseDetail: Ref<Course> = ref(new Course());
      const videoIntro: Ref<any> = ref({
        pv: 0,
        title: '',
        description: '',
        cover_img: '',
        course_type: 1,
      });

      const activedCoursewareList: Ref<ICourseware[]> = ref([]);

      const author = ref({});

      const loadCourseDetail = async () => {
        loadingCourse.value = true;

        const course = await getCourseDetail({
          id,
          community_id,
        });

        courseDetail.value = course.course_detail;

        document.title = course.course_detail.course_name;

        videoIntro.value = {
          pv: course.pv || 0,
          title: courseDetail.value.course_name,
          description: courseDetail.value.info,
          cover_img: course.cover_img || '',
          course_type: courseDetail.value.course_type,
        };

        activedCoursewareList.value = courseDetail.value.section_list.map((item) => {
          return {
            id: item.id,
            url: item.image_url,
          };
        });

        author.value = new Author(courseDetail.value);

        loadingCourse.value = false;
      };

      const questionList: Ref<Question[]> = ref([]);

      const loadQuestionList = async (course_id: number) => {
        const { question_list } = await getQuestionList({
          course_id,
          offset: 0,
          count: 100,
        });

        questionList.value = question_list.map((item) => {
          try {
            item.content = JSON.parse(item.content);
          } catch (e) {
            console.log(e);
          }
          return item;
        });
      };

      const onClickTab = (tab: any) => {
        if (tab === 1) {
          trackEvent('2244', route, {});
        }
        if (tab === 2) {
          trackEvent('2245', route, {});
        }
      };
      onMounted(async () => {
        if (route.query.token) {
          storage.set('access_token', route.query.token);
        }
        if (route.query.is_vip) {
          isVip.value = !!Number(route.query.is_vip);
        } else {
          isVip.value = true;
        }
        if (route.query.is_svip) {
          isSVip.value = !!Number(route.query.is_svip);
        } else {
          isSVip.value = true;
        }
        await loadCourseDetail();
        await loadQuestionList(courseDetail.value.course_id);
      });

      const onSendSuccessQuestion = () => {
        loadQuestionList(courseDetail.value.course_id);
      };

      return {
        onClickTab,
        author,
        videoIntro,
        courseDetail,
        activedCoursewareList,
        onSendSuccessQuestion,
        questionList,
        loadingCourse,
        isVip,
        isSVip,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .custom-video {
    height: 56.25vw;
  }

  .tab-item {
    height: calc(100vh - 56.25vw - #{px(64)});
    overflow-y: auto;

    &__light {
      background-color: white;
    }
  }

  .video-author-intro {
    margin-bottom: $box-space-medium;
  }

  .footer--fix {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    width: 100%;
  }
</style>
