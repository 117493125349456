import { Pagination } from '../base/Pagination.model';
import request from '../core/request';
import {
  IContent,
  IContentArticle,
  IContentVideo,
  IContentCourse,
  IContentSeries,
  IContentMaterial,
  IContentItem,
  IsArticleDetail,
  IsVideoDetail,
  IsCourseDetail,
  IsSeriesDetail,
  IsMaterialDetail,
  IsItemDetail,
  IAuthorCourseSeries,
  IAuthorInfo,
  IRecommend,
} from './model';

export const getContentDetail = async (data: any): Promise<IContent> => {
  return request.request<IContent>({
    url: 'ec/b/community/content/detail/get',
    method: 'GET',
    data,
  });
};

/** 获取更多推荐
 * @description:
 * @param {*}
 * @return {*}
 */
export const getRecommendList = async (data: any): Promise<Pagination<IRecommend[]>> => {
  return request.request<Pagination<IRecommend[]>>({
    url: 'ec/b/community/content/recommend/list',
    method: 'GET',
    data,
  });
};

/**
 * 获取作者信息
 * @description:
 * @param {any} data
 * @return {*}
 */
export const getAuthor = async (data: any): Promise<IAuthorInfo> => {
  return request.request<IAuthorInfo>({
    url: 'ec/b/community/author/info/get',
    method: 'GET',
    data,
  });
};

/**
 * 获取作者课程
 * @description:
 * @param {any} data
 * @return {*}
 */
export const getCourseList = async (data: any): Promise<Pagination<IAuthorCourseSeries[]>> => {
  return request.request<Pagination<IAuthorCourseSeries[]>>({
    url: 'ec/b/community/author/content/list',
    method: 'GET',
    data,
  });
};

/**
 * @description: 获取内容-文章详情
 * @param {any} data
 * @return {*}
 */
export const getArticleDetail = async (data: any): Promise<IContentArticle> => {
  const contentDetail = await getContentDetail(data);
  if (IsArticleDetail(contentDetail)) {
    return contentDetail;
  }
  throw new Error('返回类型错误');
};

/**
 * @description: 获取内容-视频详情
 * @param {any} data
 * @return {*}
 */
export const getVideoDetail = async (data: any): Promise<IContentVideo> => {
  const contentDetail = await getContentDetail(data);
  if (IsVideoDetail(contentDetail)) {
    return contentDetail;
  }
  throw new Error('返回类型错误');
};

/**
 * @description: 获取内容-课程详情
 * @param {any} data
 * @return {*}
 */
export const getCourseDetail = async (data: any): Promise<IContentCourse> => {
  const contentDetail = await getContentDetail(data);
  if (IsCourseDetail(contentDetail)) {
    return contentDetail;
  }
  throw new Error('返回类型错误');
};

/**
 * @description: 获取内容-课程系列详情
 * @param {any} data
 * @return {*}
 */
export const getSeriesDetail = async (data: any): Promise<IContentSeries> => {
  const contentDetail = await getContentDetail(data);
  if (IsSeriesDetail(contentDetail)) {
    return contentDetail;
  }
  throw new Error('返回类型错误');
};

/**
 * @description: 获取内容-素材详情
 * @param {any} data
 * @return {*}
 */
export const getMaterialDetail = async (data: any): Promise<IContentMaterial> => {
  const contentDetail = await getContentDetail(data);
  if (IsMaterialDetail(contentDetail)) {
    return contentDetail;
  }
  throw new Error('返回类型错误');
};

/**
 * @description: 获取内容-商品详情
 * @param {any} data
 * @return {*}
 */
export const getItemDetail = async (data: any): Promise<IContentItem> => {
  const contentDetail = await getContentDetail(data);

  if (IsItemDetail(contentDetail)) {
    return contentDetail;
  }
  throw new Error('返回类型错误');
};
