import { PropType, CSSProperties } from 'vue';

// Utils
import { createNamespace } from '../utils';

// Components
import Icon from '../icon';
import Loading, { LoadingType } from '../loading';

const [createComponent, bem] = createNamespace('button');

export type ButtonSize = 'large' | 'normal' | 'small' | 'mini';

export default createComponent({
  props: {
    text: String,
    icon: String,
    color: String,
    plain: Boolean,
    loading: Boolean,
    disabled: Boolean,
    iconPrefix: String,
    loadingText: String,
    loadingType: String as PropType<LoadingType>,
    tag: {
      type: String as PropType<keyof HTMLElementTagNameMap>,
      default: 'button',
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: 'normal',
    },
    loadingSize: {
      type: String,
      default: '20px',
    },
    iconPosition: {
      type: String as PropType<'left' | 'right'>,
      default: 'left',
    },
  },

  emits: ['click'],

  setup(props, { emit, slots }) {
    const renderLoadingIcon = () => {
      if (slots.loading) {
        return slots.loading();
      }

      return (
        <Loading
          class={bem('loading')}
          size={props.loadingSize}
          type={props.loadingType}
          color="currentColor"
        />
      );
    };

    const renderIcon = () => {
      if (props.loading) {
        return renderLoadingIcon();
      }

      if (props.icon) {
        return <Icon name={props.icon} class={bem('icon')} classPrefix={props.iconPrefix} />;
      }
    };

    const renderText = () => {
      let text;
      if (props.loading) {
        text = props.loadingText;
      } else {
        text = slots.default ? slots.default() : props.text;
      }

      if (text) {
        return <span class={bem('text')}>{text}</span>;
      }
    };

    const getStyle = () => {
      const { color, plain } = props;
      if (color) {
        const style: CSSProperties = {};

        style.color = plain ? color : 'white';
        style.background = plain ? 'white' : color;

        // hide border when color is linear-gradient
        if (color.indexOf('gradient') !== -1) {
          style.border = 0;
        } else {
          style.borderColor = color;
        }

        return style;
      }
    };

    const onClick = (event: MouseEvent) => {
      if (props.loading) {
        event.preventDefault();
      }
      if (!props.loading && !props.disabled) {
        emit('click', event);
      }
    };

    return () => {
      const { tag, size, plain, loading, disabled, iconPosition } = props;

      const classes = [
        bem([
          size,
          {
            plain,
            loading,
            disabled,
          },
        ]),
      ];

      return (
        <tag class={classes} style={getStyle()} disabled={disabled} onClick={onClick}>
          <div class={bem('content')}>
            {iconPosition === 'left' && renderIcon()}
            {renderText()}
            {iconPosition === 'right' && renderIcon()}
          </div>
        </tag>
      );
    };
  },
});
