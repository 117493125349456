import Watcher from './watcher';

export default class Queue {
  lockTag: boolean = false;

  lockList: Array<Object> = [];

  lock() {
    this.lockTag = true;
  }

  unlock() {
    this.lockTag = false;
  }

  isLock() {
    return this.lockTag;
  }

  waiting(request: Function) {
    return new Promise((resolve) => {
      const wm = new Watcher(
        {
          lockTag: this.lockTag,
        },
        {
          lockTag(_lockTag: any) {
            if (!_lockTag) {
              resolve(request());
            }
          },
        },
      );
      this.lockList.push(wm);
    });
  }

  async runLockList() {
    let index = this.lockList.length;

    while (index > 0) {
      (this.lockList[index - 1] as any).lockTag = false;
      this.lockList.shift();
      index--;
    }
  }

  clear() {
    this.lockList = [];
  }
}
