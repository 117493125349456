export interface IArticle {
  author: string;
  author_id: number;
  head_url: string;
  title: string;
  content: string;
}

export class Article implements IArticle {
  author = '';

  author_id = 0;

  head_url = '';

  title = '';

  content = '';

  constructor(data?: IArticle) {
    if (data) {
      this.author = data.author;
      this.author_id = data.author_id;
      this.head_url = data.head_url;
      this.title = data.title;
      this.content = data.content;
    }
  }
}
