<template>
  <div class="page goods-popular">
    <div class="content">
      <mk-swipe class="swipe" :show-indicators="false" :autoplay="3000">
        <mk-swipe-item v-if="goods.item_detail.item_video_url">
          <div class="swipe-item">
            <div class="swipe-item__video">
              <custom-video class="custom-video" :src="goods.item_detail.item_video_url"></custom-video>
            </div>
          </div>
        </mk-swipe-item>
        <mk-swipe-item v-for="(item, index) in goods.item_detail.item_img" :key="index">
          <div class="swipe-item">
            <div class="swipe-item__image">
              <img :src="item" alt="" @click="previewImage(item)" />
            </div>
          </div>
        </mk-swipe-item>
      </mk-swipe>
      <div class="copywritiing">
        {{ goods.item_detail.item_desc }}
      </div>
    </div>
    <div class="footer footer--fixed">
      <div class="footer__main">
        <div class="footer__btn" @click="onClickForward">一键推广</div>
        <div class="footer__btn" @click="onClickLinkToGoods">商品详情</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import type { Ref } from 'vue';
  import CustomVideo from '@/components/video.vue';
  import MkSwipe from '@/mk-view/swipe';
  import MkSwipeItem from '@/mk-view/swipe-item';
  import MkImagePreview from '@/mk-view/image-preview';
  import { getItemDetail, IContentItem, Goods } from '@/service';
  import { trackEvent } from '@/plugins/track/events';

  export default defineComponent({
    components: {
      MkSwipe,
      MkSwipeItem,
      CustomVideo,
    },
    setup() {
      const route = useRoute();
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';
      const goods: Ref<IContentItem> = ref({
        item_detail: new Goods(),
      } as IContentItem);

      const previewImage = (imageUrl: string) => {
        MkImagePreview({
          images: [imageUrl],
          showIndex: false,
        });
      };

      const onClickForward = async () => {
        trackEvent('2252', route, {});
        const { api } = window.lib.bridge;

        await api.communityGoodsForward({
          goodsId: goods.value.item_detail.item_id,
          title: goods.value.item_detail.item_desc,
          images: JSON.stringify(goods.value.item_detail.item_img),
        });
      };

      const onClickLinkToGoods = () => {
        trackEvent('2253', route, {});
        window.location.href = `mockuaiseller://seller/item?item_id=${goods.value.item_detail.item_id}&source_type=1`;
      };

      onMounted(async () => {
        goods.value = await getItemDetail({
          id,
          community_id,
        });
      });

      return {
        goods,
        previewImage,
        onClickForward,
        onClickLinkToGoods,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .goods-popular {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: white;
  }
  .content {
    flex: 1;
    overflow-y: auto;
  }
  /deep/.mk-swipe__track {
    align-items: center;
  }
  .swipe-item {
    display: flex;
    max-width: 100vw;
    max-height: 75vh;
    padding: px(20) px(20) px(12) px(20);
    background-color: $color-bg-light;
    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        border-radius: px(16);
      }
    }
    &__video {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .custom-video {
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: px(16);
      }
    }
  }
  // .indicator {
  //   position: absolute;
  //   right: px(5);
  //   bottom: px(5);
  //   padding: px(2) px(5);
  //   font-size: px(12);
  //   background: rgba(0, 0, 0, 0.1);
  // }
  // /deep/.mk-swipe__indicators {
  //   .mk-swipe__indicator--active {
  //     width: px(12);
  //     border-radius: px(6);
  //   }
  // }
  .copywritiing {
    @include init-font(14, 26, $color-text-highlight);

    padding: px(4) px(20) 0 px(20);
    background-color: $color-bg-light;
  }

  .footer {
    display: flex;
    background-color: #fff;
    border-top: 1px solid $border-color-primary;
    @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
      padding-bottom: env(safe-area-inset-bottom);
      padding-bottom: constant(safe-area-inset-bottom);
    }
    &__main {
      display: flex;
      width: 100%;
      padding: px(12);
    }
    &__btn {
      @include init-font(15, 25, $color-text-highlight);

      width: 50%;
      text-align: center;
      &:nth-child(1) {
        border-right: 1px solid $border-color-primary;
      }
    }
  }
</style>
