
  import { defineComponent } from 'vue';
  import MkDivider from '@/mk-view/divider';
  import AuthorIntro from '@/components/author-intro.vue';
  import VideoIntro from './video-intro.vue';

  export default defineComponent({
    components: {
      MkDivider,
      AuthorIntro,
      VideoIntro,
    },
    props: {
      author: {
        type: Object,
      },
      videoIntro: {
        type: Object,
      },
      loading: {
        type: Boolean,
      },
    },
    setup() {},
  });
