import { CSSProperties, PropType, Transition } from 'vue';
import { createNamespace } from '../utils';
import Icon from '../icon';

const [createComponent, bem] = createNamespace('tag');

export type TagType = 'default' | 'primary';

export default createComponent({
  props: {
    color: String,
    plain: Boolean,
    round: Boolean,
    textColor: String,
    bgColor: String,
    closeable: Boolean,
    tagStyle: {
      type: Object as PropType<CSSProperties>,
    },
    type: {
      type: String as PropType<TagType>,
      default: 'default',
    },
    show: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['close'],

  setup(props, { slots, emit }) {
    const onClose = (event: MouseEvent) => {
      event.stopPropagation();
      emit('close');
    };

    const getStyle = (): CSSProperties => {
      const style: any = {};
      if (props.plain) {
        style.color = props.tagStyle?.color || props.color;
        style.borderColor = props.color;
      } else {
        style.color = props.tagStyle?.color;
        style.borderColor = props.color;
      }
      style.width = props.tagStyle?.width;
      style.height = props.tagStyle?.height;
      style.backgroundColor = props.tagStyle?.backgroundColor;
      return style;
    };

    const renderTag = () => {
      const { type, plain, round, closeable } = props;

      const classes: Record<string, boolean> = { plain, round };

      const CloseIcon = closeable && <Icon name="cross" class={bem('close')} onClick={onClose} />;

      return (
        <span style={getStyle()} class={bem([classes, type])}>
          {slots.default?.()}
          {CloseIcon}
        </span>
      );
    };

    return () => (
      <Transition name={props.closeable ? 'mk-fade' : undefined}>{props.show ? renderTag() : null}</Transition>
    );
  },
});
