<template>
  <div class="video-author-intro">
    <video-intro :video-intro="videoIntro" :loading="loading"></video-intro>
    <div class="divider__wrapper">
      <mk-divider></mk-divider>
    </div>
    <author-intro class="author-intro" :author="author" :loading="loading"></author-intro>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import MkDivider from '@/mk-view/divider';
  import AuthorIntro from '@/components/author-intro.vue';
  import VideoIntro from './video-intro.vue';

  export default defineComponent({
    components: {
      MkDivider,
      AuthorIntro,
      VideoIntro,
    },
    props: {
      author: {
        type: Object,
      },
      videoIntro: {
        type: Object,
      },
      loading: {
        type: Boolean,
      },
    },
    setup() {},
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .author-intro {
    margin-bottom: $box-space-medium;
  }
  .divider__wrapper {
    background-color: white;
  }
</style>
