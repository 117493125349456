import trackAction from './action';

export function eventParams(event_id: any, $route: any, target: any, options?: any) {
  const tempData: any = {};
  tempData.type = 'u_click';
  tempData.page_id = $route.meta.page_id;
  tempData.tag = `0000.${event_id}`;
  tempData.trace_tag = `10.${$route.meta.page_id}.${tempData.tag}`;
  tempData.options = options || $route.query;
  tempData.target = target ? target.innerText : '';
  tempData.route = $route.fullPath;
  return tempData;
}
export function pageParams(event_id: any, $route: any, duration = '', options?: any) {
  console.log($route.meta.page_id);
  const tempData: any = {};
  tempData.type = event_id;
  if ($route && $route.meta && $route.meta.page_id) {
    tempData.page_id = $route.meta.page_id;
  } else {
    tempData.page_id = '0000';
  }
  tempData.tag = '0000.0000';
  tempData.trace_tag = `10.${tempData.page_id}.${tempData.tag}`;
  tempData.options = Object.assign($route.query, options);
  tempData.duration = duration;
  tempData.route = $route.fullPath;
  return tempData;
}
const track: any = {
  /**
   * @name UVPV 固定名称不支持修改
   * @desc UV、PV埋点
   * @param {Object} context 当前上下文
   */
  UVPV(option: any) {
    trackAction('p_show', pageParams('p_show', option));
  },
  /**
   * @name TONP 固定名称不支持修改
   * @desc 页面停留时间埋点（Time on Page）
   * @param {Object} context 当前上下文
   * @param {Timestamp} et 进入页面时间
   * @param {Timestamp} dt 离开页面时间
   */
  TONP(option: any, { et, dt }: any) {
    trackAction('p_hide', pageParams('p_hide', option, `${dt - et}`));
  },
};

const eventPoint = [2243, 2244, 2245, 2246, 2247, 2248, 2249, 2251];
eventPoint.forEach((item) => {
  track[item] = function log({ $route }: any, { target }: any) {
    trackAction(item, eventParams(item, $route, target));
  };
});

export const trackEvent = function (eventId: any, $route: any, target: any, options?: any) {
  trackAction(eventId, eventParams(eventId, $route, target, options));
};

export default track;
