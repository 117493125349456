import { defineComponent, onMounted, ref } from 'vue';
import { addUnit } from '../utils';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
    ellipsis: {
      type: Number,
      default: 2,
    },
    customStyle: {
      type: Object,
      default: () => {
        return {
          fontSize: 13,
          lineHeight: 20,
        };
      },
    },
  },
  emits: ['showMore'],
  setup(props, { emit }) {
    const nEllipsis = ref(props.ellipsis);

    const showMore = () => {
      emit('showMore');
      nEllipsis.value = 0;
    };

    onMounted(() => {
      if (
        document.querySelector('.text-shadow')?.clientHeight === undefined ||
        (document.querySelector('.text-shadow')?.clientHeight as Number) <=
          props.customStyle.lineHeight * nEllipsis.value
      ) {
        nEllipsis.value = 0;
      }
    });

    const getStyle = () => {
      const style: any = {
        fontSize: addUnit(props.customStyle.fontSize),
        lineHeight: addUnit(props.customStyle.lineHeight),
      };
      if (nEllipsis.value) {
        style.height = props.customStyle.lineHeight * nEllipsis.value;
        style['-webkit-line-clamp'] = nEllipsis.value;
      } else {
        style.height = 'auto';
      }

      return style;
    };

    const renderShowMore = () => {
      if (nEllipsis.value > 0) {
        return (
          <div class="action" onClick={showMore}>
            展开更多
          </div>
        );
      }
    };

    return () => (
      <div class="ellipsis">
        <div class="text" style={getStyle()}>
          {props.text}
        </div>
        <div class="text-shadow">{props.text}</div>
        {renderShowMore()}
      </div>
    );
  },
});
