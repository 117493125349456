
  import { defineComponent, onMounted, toRefs, watch, ref, nextTick } from 'vue';
  import ChimeeMobilePlayer from '@mk/chimee-mobile-player';

  export default defineComponent({
    emits: ['ended'],
    props: {
      src: {
        type: String,
        default: '',
      },
    },
    setup(props, { emit }) {
      const { src } = toRefs(props);
      const { env } = window.lib;
      const networkStatus = ref('');
      let videoSetTimeout: any;

      let player: any = null;

      const videoPlay = (_player: any) => {
        if (videoSetTimeout) {
          clearTimeout(videoSetTimeout);
          videoSetTimeout = null;
        }
        videoSetTimeout = setTimeout(() => {
          if (env === 'mockuai') {
            // 根据网络状态决定是否播放视频
            if (networkStatus.value === 'wifi') {
              _player.play();
            } else {
              _player.pause();
            }
          }
          if (env === 'browser') {
            _player.play();
          }
          clearTimeout(videoSetTimeout);
          videoSetTimeout = null;
        }, 1000);
      };

      const initVideo = async (_src: any) => {
        player = new ChimeeMobilePlayer({
          wrapper: '#wrapper', // video dom容器
          _src,
          autoplay: true,
          controls: true,
          playsInline: true,
          preload: true,
          x5VideoPlayerFullscreen: true,
          x5VideoOrientation: true,
          xWebkitAirplay: true,
          x5VideoPlayerType: 'h5-page',
          muted: false,
        });
        player.ready.then(() => {
          videoPlay(player);
          window.document
            .querySelector('#wrapper')
            ?.querySelector('video')
            ?.addEventListener(
              'ended',
              () => {
                emit('ended');
              },
              false,
            );
        });
      };

      onMounted(async () => {
        if (env === 'mockuai') {
          const { bridge, api } = window.lib.bridge;
          networkStatus.value = (await api.getNetworkStatus()).status;
          api.startNetworkStatusMonitoring(); /* status (unknown/未知网络, notReachable/没有网络, wwan/手机网络, wifi/无线网络) */
          bridge.register(
            'networkStatusChange',
            (response: { status: 'unknown' | 'notReachable' | 'wwan' | 'wifi' }) => {
              networkStatus.value = response.status;
            },
          );
        }
        initVideo(src.value);
      });

      const changeVideo = (_src: any) => {
        player.load(_src);
        player.ready.then(() => {
          videoPlay(player);
        });
      };

      watch(src, () => {
        changeVideo(src.value);
      });

      watch(networkStatus, () => {
        videoPlay(player);
      });
    },
  });
