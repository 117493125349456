
  import { defineComponent, nextTick, onMounted, watch } from 'vue';
  import MkCard from '@/mk-view/card';
  import Utils from '@mk/mk-utils';

  export default defineComponent({
    components: { MkCard },
    emits: ['activeCourse', 'showSeriesCourseListDetail'],
    props: {
      seriesCourseList: {
        type: Array,
        default: () => {
          return [];
        },
      },
      activedCourse: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    setup(props, { emit }) {
      const onClickActiveCourse = (course: any) => {
        emit('activeCourse', course);
      };
      const onClickShowSeriesCourseListDetail = () => {
        emit('showSeriesCourseListDetail', props.activedCourse);
      };
      const filterDuration = (value: number) => {
        return Utils.MKString.formatDuration(value, 'mm:ss');
      };
      watch(props, (value) => {
        const { seriesCourseList, activedCourse } = value;
        const index = seriesCourseList.findIndex((item: any) => {
          return item.course_id === activedCourse.course_id;
        });
        nextTick(() => {
          if (index === 0) {
            (document.querySelector('.series-course-list .content') as any).scrollLeft = 0;
            return;
          }
          (document.querySelector('.series-course-list .content') as any).scrollLeft =
            141 * index - ((document.querySelector('.series-course-list .content') as any).offsetWidth - 131) / 2;
        });
      });
      return { onClickActiveCourse, onClickShowSeriesCourseListDetail, filterDuration };
    },
  });
