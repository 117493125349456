import crypto from 'crypto-js';
/**
 * 自动补充请求地址
 * @param {String} baseUrl 拼接地址
 * @param {String} url 请求地址
 */
export function getAbsoluteURL(baseUrl = '', url = '') {
  if (isAbsoluteURL(url)) {
    return url;
  }
  if (!url) {
    return baseUrl;
  }
  if (!url.startsWith('/')) {
    url = `/${url}`;
  }
  return `${baseUrl}${url}`;
}

/**
 * 是否是完整地址
 * @param {String} url 地址信息
 */
export function isAbsoluteURL(url: string) {
  return /^http[s]?:\/\//.test(url);
}

/**
 *
 * @param {*} requestData
 */
export function makeSign(requestData: any, appPwd: string) {
  const str = Object.keys(requestData)
    .sort()
    .map(key => `${key}=${requestData[key]}`)
    .join('&');
  return crypto.MD5(appPwd + str + appPwd).toString();
}
