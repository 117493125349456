export class AuthorInfo {
  author = '';

  author_id = 0;

  head_url = '';

  title = '';

  show_map: IAuthorShowMap = {
    article_contain: false,
    course_contain: false,
    video_contain: false,
  };

  constructor(data?: IAuthorInfo) {
    if (!data) {
      return;
    }
    this.author_id = data.author_id;
    this.author = data.author_name;
    this.head_url = data.author_head_logo;
    this.title = data.title;
    this.show_map = data.show_map;
  }
}

export interface IAuthorInfo {
  author_id: number;
  author_name: string;
  author_head_logo: string;
  title: string;
  show_map: IAuthorShowMap;
}

interface IAuthorShowMap {
  article_contain: boolean;
  course_contain: boolean;
  video_contain: boolean;
}
