
  import { defineComponent, onMounted, ref } from 'vue';
  import type { Ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { Article, Author, getArticleDetail, IContentArticle } from '@/service';
  import AuthorIntro from '@/components/author-intro.vue';

  export default defineComponent({
    components: {
      AuthorIntro,
    },
    props: {},
    setup() {
      const route = useRoute();
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';
      const artcile: Ref<IContentArticle> = ref({
        main_title: '',
        sub_title: '',
        cover_img: '',
        description: '',
        content_type: 1,
        article_detail: new Article(),
      } as IContentArticle);

      const author: Ref<Author> = ref(new Author());
      const loading = ref(true);
      onMounted(async () => {
        artcile.value = await getArticleDetail({
          community_id,
          id,
        });

        document.title = artcile.value.main_title;

        artcile.value.article_detail.content = decodeURIComponent(artcile.value.article_detail.content);
        author.value = new Author(artcile.value.article_detail);
        loading.value = false;
      });
      return {
        artcile,
        author,
        loading,
      };
    },
  });
