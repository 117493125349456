class Storage {
  memoryCache: Map<any, any>;

  constructor() {
    this.memoryCache = new Map();
  }

  /**
   * 缓存当前值
   * @param key 缓存key
   * @param val 缓存内容
   */
  set(key: string, value: any) {
    if (value) {
      this.memoryCache.set(key, value);
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }

  /**
   * 获取缓存内容
   * @param key 缓存key
   */
  get(key: string) {
    let data = this.memoryCache.get(key);
    if (typeof data !== 'undefined') {
      return data;
    }
    data = window.localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return '';
  }

  /**
   * 删除缓存
   * @param key 缓存key
   */
  remove(key: string) {
    this.memoryCache.delete(key);
    window.localStorage.removeItem(key);
  }
}

const storage = new Storage();

export default storage;
