export interface IVideo {
  author: string;
  author_id: number;
  head_url: string;
  title: string;
  video_duration: number;
  video_url: string;
  video_desc: string;
}

export class Video implements IVideo {
  author = '';

  author_id = 0;

  head_url = '';

  title = '';

  video_duration = 0;

  video_url = '';

  video_desc = '';

  constructor(data?: IVideo) {
    if (data) {
      this.author = data.author;
      this.author_id = data.author_id;
      this.head_url = data.head_url;
      this.title = data.title;
      this.video_duration = data.video_duration;
      this.video_url = data.video_url;
      this.video_desc = data.video_desc;
    }
  }
}
