import { Course } from './Course.model';

export interface ISeries {
  author: string;
  author_id: number;
  head_url: string;
  title: string;
  course_id: number;
  course_type: number;
  course_name: string;
  total_course_num: number;
  info: string;
  sub_course_list: Course[];
  sub_course_detail_list: Course[];
}

export class Series implements ISeries {
  author = '';

  author_id = 0;

  head_url = '';

  title = '';

  course_id = 0;

  course_name = '';

  course_type = 1;

  info = '';

  total_course_num = 0;

  sub_course_list: Course[] = [];

  sub_course_detail_list: Course[] = [];

  constructor(data?: ISeries) {
    if (!data) {
      return;
    }
    this.author = data.author;
    this.author_id = data.author_id;
    this.head_url = data.head_url;
    this.title = data.title;
    this.course_id = data.course_id;
    this.course_name = data.course_name;
    this.course_type = data.course_type;
    this.info = data.info;
    this.total_course_num = data.total_course_num;
    this.sub_course_list = data.sub_course_list;
    this.sub_course_detail_list = data.sub_course_detail_list;
  }
}
