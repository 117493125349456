import request from '../core/request';
import { IUser } from './model';

export async function getUserInfo(data: any = {}): Promise<IUser> {
  return request.request<IUser>({
    url: 'ec/b/user/get/user/info',
    method: 'GET',
    data,
  });
}
