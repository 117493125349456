import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Index from '@/views/index.vue';
import AuthorLecturer from '@/views/author/lecturer/index.vue';
import ContentMaterial from '@/views/content/material/index.vue';
import ContentArticle from '@/views/content/article/index.vue';
import GoodsPopular from '@/views/goods/goods-popular/index.vue';
import Video from '@/views/video/video/index.vue';
import VideoCourse from '@/views/video/course/index.vue';
import VideoCourseSeries from '@/views/video/course-series/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/index',
    component: Index,
  },
  {
    path: '/author/lecturer',
    component: AuthorLecturer,
    meta: {
      title: '讲师介绍',
      page_id: '1099',
    },
  },
  {
    path: '/content/material',
    component: ContentMaterial,
    meta: {
      title: '素材分享',
      page_id: '1100',
    },
  },
  {
    path: '/content/article',
    component: ContentArticle,
    meta: {
      title: '文章详情',
      page_id: '1100',
    },
  },
  {
    path: '/goods/goods-popular',
    component: GoodsPopular,
    meta: {
      title: '爆款商品',
      page_id: '1100',
    },
  },
  {
    path: '/video/video',
    component: Video,
    meta: {
      title: '视频详情',
      page_id: '1100',
    },
  },
  {
    path: '/video/course',
    component: VideoCourse,
    meta: {
      title: '课程详情',
      page_id: '1100',
    },
  },
  {
    path: '/video/course-series',
    component: VideoCourseSeries,
    meta: {
      title: '系列课程',
      page_id: '1100',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title as string;
  }
  next();
});

export default router;
