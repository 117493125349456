import axios from 'axios';
import Queue from './queue';
import Exception from './exception';
import { getAbsoluteURL, makeSign } from './utils';
import { IRequestConfig, IRequestParams } from '../types';

const sessionQueue: any = new Queue();
const accessQueue: any = new Queue();
const refreshQueue: any = new Queue();
const exception: Exception = new Exception();

export default class Request {
  private appKey: string = '';

  private appPwd: string = '';

  private baseUrl: string = '';

  private needLogin: boolean = true;

  private needSessionToken: boolean = false;

  private getSessionToken: Function;

  private setSessionToken: Function;

  private getAccessToken: Function;

  private setAccessToken: Function;

  private getRefreshToken: Function;

  private setRefreshToken: Function;

  private isLogin: Function;

  getSessionTokenUrl: string = '';

  getAccessTokenUrl: string = '';

  getRefreshTokenUrl: string = '';

  getSessionTokenFunc?: Function;

  getAccessTokenFunc?: Function;

  getRefreshTokenFunc?: Function;

  logout: Function;

  loginData: any = {};

  private errorTaskMap: Map<string, any[]> = new Map();

  constructor(config: IRequestConfig) {
    this.appKey = config.appKey;
    this.appPwd = config.appPwd;
    this.baseUrl = config.baseUrl;

    this.getSessionToken = config.getSessionToken;
    this.setSessionToken = config.setSessionToken;
    this.getAccessToken = config.getAccessToken;
    this.setAccessToken = config.setAccessToken;
    this.getRefreshToken = config.getRefreshToken;
    this.setRefreshToken = config.setRefreshToken;

    this.isLogin = config.isLogin;
    this.logout = config.logout;

    if (config.needSessionToken !== undefined) this.needSessionToken = config.needSessionToken;
    if (config.needLogin !== undefined) this.needLogin = config.needLogin;

    if (config.getSessionTokenUrl) this.getSessionTokenUrl = config.getSessionTokenUrl;
    if (config.getAccessTokenUrl) this.getAccessTokenUrl = config.getAccessTokenUrl;
    if (config.getRefreshTokenUrl) this.getRefreshTokenUrl = config.getRefreshTokenUrl;
    if (config.getSessionTokenFunc) this.getSessionTokenFunc = config.getSessionTokenFunc;
    if (config.getAccessTokenFunc) this.getAccessTokenFunc = config.getAccessTokenFunc;
    if (config.getRefreshTokenFunc) this.getRefreshTokenFunc = config.getRefreshTokenFunc;

    // if (config.exceptionOption) exception.init(config.exceptionOption);
  }

  async loadSessionToken<D>(params: IRequestParams): Promise<D> {
    if (sessionQueue.isLock() && params.url !== this.getSessionTokenUrl) {
      console.log('Lock');
      return sessionQueue.waiting(async () => {
        console.log('unLock');
        return this.request(params);
      }) as Promise<D>;
    }

    sessionQueue.lock();

    if (this.getSessionTokenFunc) {
      await this.getSessionTokenFunc();
    } else if (this.getSessionTokenUrl) {
      const res = await this.runRequest({
        url: this.getSessionTokenUrl,
        method: 'GET',
      });
      this.setSessionToken(res.session_token);
    }

    sessionQueue.unlock();

    await sessionQueue.runLockList();
    return this.runRequest(params);
  }

  async loadAccessToken<D>(params: IRequestParams): Promise<D> {
    if (accessQueue.isLock() && params && params.url !== this.getAccessTokenUrl) {
      return (await accessQueue.waiting(async () => {
        return this.request(params);
      })) as Promise<D>;
    }

    accessQueue.lock();

    if (this.getAccessTokenFunc) {
      await this.getAccessTokenFunc();
    } else if (this.getAccessTokenUrl) {
      const res = await this.runRequest({
        url: this.getAccessTokenUrl,
        data: this.loginData,
        method: 'POST',
      });
      this.setAccessToken(res.access_token);
      this.setRefreshToken(res.refresh_token);
    }

    accessQueue.unlock();

    await accessQueue.runLockList();
    return this.runRequest(params);
  }

  async loadRefreshToken<D>(params: IRequestParams): Promise<D> {
    if (refreshQueue.isLock()) {
      return (await refreshQueue.waiting(async () => {
        return this.request(params);
      })) as Promise<D>;
    }

    refreshQueue.lock();

    if (this.getRefreshTokenFunc) {
      await this.getRefreshTokenFunc();
    } else if (this.getRefreshTokenUrl) {
      const res = await this.runRequest({
        url: this.getRefreshTokenUrl,
        data: this.loginData,
        method: 'POST',
      });
      this.setAccessToken(res.access_token);
      this.setRefreshToken(res.refresh_token);
    }
    refreshQueue.unlock();

    await refreshQueue.runLockList();
    return this.runRequest(params);
  }

  async request<D>(params: IRequestParams): Promise<D> {
    // needSessionToken为true，没有session_token则去获取session_token
    if (this.needSessionToken && !this.getSessionToken()) {
      return this.loadSessionToken(params);
    }
    // 如果需要登录并且没有登录则去登录
    if (this.needLogin && !this.getAccessToken()) {
      const loadAccessToken: any = await this.loadAccessToken(params);
      return loadAccessToken;
    }

    // 验证请求状态，判断是立即执行还是放入队列
    return this.runRequest(params) as Promise<D>;
  }

  async runRequest(params: IRequestParams) {
    const response = await axios(this.handleRequestParams(params));
    return this.handleResponse(response.data, params);
  }

  handleRequestParams(params: IRequestParams): Object {
    const axiosParams: any = {
      method: params.method,
      url: getAbsoluteURL(params.baseUrl || this.baseUrl, params.url),
      responseType: 'json',
    };

    const data = this.handleRequestData(params);

    if (axiosParams.method === 'GET') {
      axiosParams.params = data;
    }

    if (axiosParams.method === 'POST') {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        data[key] = data[key].toString().replace(/\r\n/g, '\n').replace(/\n/g, '\r\n');
        formData.append(key, data[key]);
      });
      axiosParams.data = formData;
    }

    return axiosParams;
  }

  handleRequestData(params: any) {
    const data = params.data || {};

    delete data.api_sign;
    data.format = 'json';
    data.timestamp = Math.floor(Date.now() / 1000);
    data.app_key = this.appKey;

    if (this.needSessionToken) {
      data.session_token = this.getSessionToken();
    }
    if (!(params.needLogin === false && this.needLogin === false)) {
      data.access_token = this.getAccessToken();
    }

    Object.keys(data).forEach((key) => {
      if (data[key] === undefined || data[key] === '') {
        delete data[key];
      }
    });

    data.api_sign = makeSign(data, this.appPwd);
    return data;
  }

  // 处理回调
  async handleResponse(data: any, params: IRequestParams) {
    switch (exception.codeMap.get(data.code)) {
      case 'success':
        this.removeError(params);
        return data.data;
      // session_token无效或已过期
      case 'sessionError':
        this.addError(params);
        return this.loadSessionToken(params);
      // refresh_token无效或已过期
      case 'accessError':
        this.addError(params);
        return this.loadAccessToken(params);
      // access_token无效或已过期
      case 'refreshError':
        this.addError(params);
        return this.loadRefreshToken(params);
      default:
        this.addError(params);
        // 对错误做统一处理，无需把原始错误暴露给用户
        switch (data.code) {
          case 40001:
            throw new Error('服务器开小差了');
          case 20002:
            throw new Error('网络异常');
          case 50007:
            throw new Error('服务器繁忙');
          default:
            throw new Error(data.msg);
        }
    }
  }

  addError(params: any) {
    if (this.errorTaskMap.has(params.url)) {
      const errorTask = this.errorTaskMap.get(params.url) as Array<any>;
      if (errorTask.length > 10) {
        window.location.href = '/waiting';
        this.clearError();
        return;
      }
      errorTask.push(params);
      this.errorTaskMap.set(params.url, errorTask);
    } else {
      const errorTask = [params];
      this.errorTaskMap.set(params.url, errorTask);
    }
  }

  removeError(params: any) {
    if (this.errorTaskMap.has(params.url)) {
      this.errorTaskMap.delete(params.url);
    }
  }

  clearError() {
    this.errorTaskMap.clear();
  }
}
