
  import { useRouter, useRoute } from 'vue-router';
  import { AuthorCourseSeries } from '@/service';
  import { defineComponent, Ref, ref, toRef, watch } from 'vue';
  import Utils from '@mk/mk-utils';
  import { trackEvent } from '@/plugins/track/events';
  import storage from '@/common/storage';

  export default defineComponent({
    components: {},
    emits: [],
    props: {
      recommendList: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    setup(props) {
      const router = useRouter();
      const route = useRoute();
      const nRecommendList: Ref<any[]> = ref([]);
      const recommendList: Ref<any[]> = toRef(props, 'recommendList');

      const isReadRecommend = (id: number) => {
        if (!storage.get('readVideoList')) {
          return false;
        }
        const readVideoList = storage.get('readVideoList');

        return readVideoList.findIndex((item: number) => item === id) >= 0;
      };

      const refreshRecommendList = () => {
        const readList: any[] = [];
        nRecommendList.value = recommendList.value.reduce((pre: any[], item: any) => {
          if (!isReadRecommend(item.content_id)) {
            pre.push(item);
          } else {
            readList.push(item);
          }
          return pre;
        }, []);
        nRecommendList.value = [...nRecommendList.value, ...readList];
      };

      watch(recommendList, () => {
        refreshRecommendList();
      });

      const linkToRecommend = (item: AuthorCourseSeries) => {
        trackEvent('2249', route, {}, { content_id: item.content_id });

        switch (item.content_type) {
          case 2: {
            router.replace({
              path: '/video/video',
              query: {
                id: item.content_id,
                community_id: route.query.community_id,
              },
            });
            break;
          }
          case 3: {
            router.replace({
              path: '/video/course',
              query: {
                id: item.content_id,
                community_id: route.query.community_id,
              },
            });
            break;
          }
          case 4: {
            router.replace({
              path: '/video/course-series',
              query: {
                id: item.content_id,
                community_id: route.query.community_id,
              },
            });
            break;
          }
          default: {
            break;
          }
        }
      };

      const filterDuration = (value: number) => {
        return Utils.MKString.formatDuration(value, 'mm:ss');
      };

      return {
        linkToRecommend,
        filterDuration,
        nRecommendList,
      };
    },
  });
