// 异常处理

export default class Exception {
  // 返回码
  codeMap = new Map([
    [10000, 'success'],
    [50001, 'sessionError'],
    [50002, 'sessionError'],
    [50003, 'refreshError'],
    [50005, 'refreshError'],
    [50006, 'refreshError'],
    [50004, 'accessError'],
    [50007, 'waiting'],
  ]);

  exceptionCodeList: Array<number> = []; // 异常code值

  constructor(config?: any) {
    if (config) {
      this.init(config);
    }
  }

  init(config: any) {
    if (config.codeOption) {
      this.codeMap = new Map(config.codeOption);
    }
    Object.keys(this.codeMap.keys()).forEach((key: any) => {
      if (this.codeMap.get(key) !== 'success') {
        this.exceptionCodeList.push(key);
      }
    });
  }
}
