<template>
  <div class="page course-series">
    <mk-sticky>
      <div class="custom-video">
        <custom-video v-if="isVip" class="custom-video" :src="videoDetail.video_url"></custom-video>
        <img v-else class="custom-video-cover" :src="videoIntro.cover_img" />
      </div>
    </mk-sticky>
    <video-author-intro :author="author" :video-intro="videoIntro"></video-author-intro>
    <more-recommend v-if="isVip" :recommend-list="recommendList"></more-recommend>
    <open-vip v-else></open-vip>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, Ref, ref, watch } from 'vue';
  import { RouteLocationNormalizedLoaded, useRoute, useRouter } from 'vue-router';
  import MkSticky from '@/mk-view/sticky';
  import CustomVideo from '@/components/video.vue';
  import { getVideoDetail, getRecommendList, Author, Video, IRecommend } from '@/service';
  import storage from '@/common/storage';
  import { IVideoIntro } from '../components/types';
  import VideoAuthorIntro from '../components/video-author-intro.vue';
  import OpenVip from '../components/open-vip.vue';
  import MoreRecommend from './components/more-recommend.vue';

  export default defineComponent({
    components: {
      MkSticky,
      CustomVideo,
      VideoAuthorIntro,
      MoreRecommend,
      OpenVip,
    },
    props: {},
    setup() {
      const route = useRoute();
      const router = useRouter();
      const videoDetail: Ref<Video> = ref(new Video());
      const videoIntro: Ref<IVideoIntro> = ref({
        pv: 0,
        title: '',
        description: '',
        cover_img: '',
      });
      const isVip = ref(true);
      const author = ref({});
      const recommendList: Ref<IRecommend[]> = ref([]);

      const init = async (val?: RouteLocationNormalizedLoaded, oldVal?: RouteLocationNormalizedLoaded) => {
        if (val && oldVal && val.query.id === oldVal.query.id) {
          return;
        }

        const id = Number(route.query.id);
        const community_id = route.query.community_id ? Number(route.query.community_id) : '';
        if (route.query.is_vip) {
          isVip.value = !!Number(route.query.is_vip);
        } else {
          isVip.value = true;
        }

        addReadRecommend(id);

        const video = await getVideoDetail({
          id,
          community_id,
        });

        videoDetail.value = video.video_detail;
        console.log(video.cover_img);
        videoIntro.value = {
          pv: video.pv || 0,
          title: video.main_title,
          description: videoDetail.value.video_desc,
          cover_img: video.cover_img || '',
        };

        document.title = video.main_title;

        author.value = new Author(video.video_detail);

        recommendList.value = (
          await getRecommendList({
            content_id: id,
            community_id,
            extra_data: JSON.stringify({
              offset: 0,
            }),
            page_size: 60,
          })
        ).data;
      };

      const addReadRecommend = (id: number) => {
        let readVideoList;
        if (!storage.get('readVideoList')) {
          readVideoList = [];
        } else {
          readVideoList = storage.get('readVideoList');
        }

        if (!(readVideoList.findIndex((item: number) => item === id) >= 0)) {
          readVideoList.push(id);
        }

        storage.set('readVideoList', readVideoList);
      };

      watch(router.currentRoute, init);

      onMounted(async () => {
        if (route.query.token) {
          storage.set('access_token', route.query.token);
        }
        init();
      });

      return {
        videoIntro,
        videoDetail,
        author,
        recommendList,
        isVip,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .custom-video {
    height: 56.25vw;
  }

  .custom-video-cover {
    display: block;
    width: 100%;
    margin: 0 auto;
    object-fit: cover;
  }

  .question-list {
    padding-bottom: px(64);
  }

  .footer--fix {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    width: 100%;
  }
</style>
