
  import { defineComponent } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { AuthorCourseSeries } from '@/service';
  import Utils from '@mk/mk-utils';
  import { trackEvent } from '@/plugins/track/events';

  export default defineComponent({
    components: {},
    props: {
      authorCourseList: {
        type: Array,
      },
    },
    setup() {
      const router = useRouter();
      const route = useRoute();

      const linkToAuthorCourse = (item: AuthorCourseSeries) => {
        trackEvent('2251', route, {}, { content_id: item.content_id });

        if (item.content_type === 1) {
          router.push({
            path: '/content/article',
            query: {
              id: item.content_id,
              community_id: item.community_id,
            },
          });
        }
        if (item.content_type === 2) {
          router.push({
            path: '/video/video',
            query: {
              id: item.content_id,
              community_id: item.community_id,
            },
          });
        }
        if (item.content_type === 3) {
          router.push({
            path: '/video/course',
            query: {
              id: item.content_id,
              community_id: item.community_id,
            },
          });
        }
        if (item.content_type === 4) {
          router.push({
            path: '/video/course-series',
            query: {
              id: item.content_id,
              community_id: item.community_id,
            },
          });
        }
      };

      const filterDuration = (value: number) => {
        return Utils.MKString.formatDuration(value, 'mm:ss');
      };

      return {
        linkToAuthorCourse,
        filterDuration,
      };
    },
  });
