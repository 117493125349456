<template>
  <div class="more-recommend">
    <div v-if="authorCourseList.length" class="content">
      <div v-for="item in authorCourseList" :key="item.content_id" class="recommend" @click="linkToAuthorCourse(item)">
        <div class="recommend__content">
          <div class="recommend__title">{{ item.main_title }}</div>
          <div class="recommend__info">
            <div class="view">
              <i class="iconfont iconview"></i>
              {{ item.pv }}
            </div>
            <div class="duration">
              <template v-if="item.content_type === 3">
                <i class="iconfont icontime"></i>
                {{ filterDuration(item.video_duration) }}
              </template>
              <template v-if="item.content_type === 4">
                <i class="iconfont iconcourse"></i>
                共{{ item.total_course_num }}集
              </template>
            </div>
          </div>
        </div>
        <div class="recommend__cover">
          <img :src="item.cover_img" alt="" />
        </div>
      </div>
    </div>
    <div v-if="!authorCourseList.length" class="content--default">
      <img src="https://mktv-in-cdn.mockuai.com/16214144032356883.png" alt="" />
      暂无内容
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { AuthorCourseSeries } from '@/service';
  import Utils from '@mk/mk-utils';
  import { trackEvent } from '@/plugins/track/events';

  export default defineComponent({
    components: {},
    props: {
      authorCourseList: {
        type: Array,
      },
    },
    setup() {
      const router = useRouter();
      const route = useRoute();

      const linkToAuthorCourse = (item: AuthorCourseSeries) => {
        trackEvent('2251', route, {}, { content_id: item.content_id });

        if (item.content_type === 1) {
          router.push({
            path: '/content/article',
            query: {
              id: item.content_id,
              community_id: item.community_id,
            },
          });
        }
        if (item.content_type === 2) {
          router.push({
            path: '/video/video',
            query: {
              id: item.content_id,
              community_id: item.community_id,
            },
          });
        }
        if (item.content_type === 3) {
          router.push({
            path: '/video/course',
            query: {
              id: item.content_id,
              community_id: item.community_id,
            },
          });
        }
        if (item.content_type === 4) {
          router.push({
            path: '/video/course-series',
            query: {
              id: item.content_id,
              community_id: item.community_id,
            },
          });
        }
      };

      const filterDuration = (value: number) => {
        return Utils.MKString.formatDuration(value, 'mm:ss');
      };

      return {
        linkToAuthorCourse,
        filterDuration,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .more-recommend {
    width: 100%;
    padding: 0 px(10);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: px(48);
      padding: 0 $box-space-large;
      .title {
        @include init-font(16, 16, $color-text-highlight);

        font-weight: bold;
      }
    }
    .content {
      width: 100%;

      &--default {
        padding: px(50) px(100);
        text-align: center;
        @include init-font(14, 20, #999);

        img {
          width: px(170);
          height: px(131);
          margin-bottom: px(10);
        }
      }
      .recommend {
        display: flex;
        margin-bottom: px(10);
        padding: px(12);
        background-color: $color-bg-light;
        border-radius: 8px;
        &__content {
          flex-shrink: 1;
          width: 100%;
          margin-right: px(35);
        }
        &__title {
          @include init-font(14, 20, $color-text-highlight);
          @include ellipsis(2);

          height: px(40);
          margin-bottom: px(8);
          font-weight: bold;
        }
        &__author {
          @include init-font(12, 12, $color-text-highlight);

          display: flex;
          align-items: center;
          margin-bottom: px(10);
          .portrait {
            width: px(20);
            height: px(20);
            margin-right: px(4);
            border-radius: 50%;
          }
        }
        &__info {
          @include init-font(10, 10, $color-text-secondary);

          display: flex;
          align-items: center;
          .view {
            width: px(50);
            margin-right: px(16);
          }
          i {
            color: #bbb;
            font-size: px(10);
          }
        }
        &__cover {
          flex-shrink: 0;
          width: px(100);
          img {
            @include image-cover();

            height: px(60);
            border-radius: px(8);
          }
        }
      }
    }
  }
</style>
