<template>
  <div class="page author-lecturer">
    <div class="header">
      <i class="iconfont iconarrowleft icon-back" @click="navigateBack"></i>
      <author-intro class="author-intro" theme="header" :loading="loadingAuthor" :author="anchorInfo"></author-intro>
    </div>
    <div class="tags">
      <mk-tag
        v-for="item in tagOption"
        v-show="item.show"
        :key="item.contentType"
        class="tag"
        round
        :plain="item.active"
        :type="item.active ? 'primary' : 'default'"
        :tag-style="{
          color: item.active ? '' : '#666',
          backgroundColor: item.active ? '#FEF5F2' : '#fff',
          width: '60px',
          height: '24px',
        }"
        @click="onClickTag(item.contentType)"
      >
        {{ item.name }}
      </mk-tag>
    </div>
    <author-course-list :author-course-list="authorCourseList"></author-course-list>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
  import type { Ref } from 'vue';
  import { useRoute } from 'vue-router';
  import MkTag from '@/mk-view/tag';
  import { getCourseList, IAuthorCourseSeries, getAuthor, AuthorInfo } from '@/service';
  import AuthorIntro from '@/components/author-intro.vue';
  import AuthorCourseList from './components/author-course-list.vue';

  export default defineComponent({
    components: {
      MkTag,
      AuthorIntro,
      AuthorCourseList,
    },
    props: {},
    setup() {
      const { env } = window.lib;
      if (env === 'mockuai') {
        const { api } = window.lib.bridge;
        api.navigationBarConfig({
          hiddenNavigationBar: true,
        });
      }
      const navigateBack = () => {
        const { api } = window.lib.bridge;
        api.navigateBack({
          hiddenNavigationBar: true,
        });
      };

      const loadingAuthor = ref(true);

      const anchorInfo = ref(new AuthorInfo());
      const authorCourseList: Ref<IAuthorCourseSeries[]> = ref([]);

      const route = useRoute();
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';

      const tagOption = ref([
        {
          contentType: 0,
          name: '全部',
          show: true,
          active: true,
        },
        {
          contentType: 1,
          name: '课程',
          show: false,
          active: false,
        },
        {
          contentType: 3,
          name: '文章',
          show: false,
          active: false,
        },
        {
          contentType: 2,
          name: '短视频',
          show: false,
          active: false,
        },
      ]);

      const getActiveTag = () => {
        const option = tagOption.value.find((item) => {
          return item.active;
        });
        return option?.contentType;
      };

      const setActiveTag = (contentType: number) => {
        tagOption.value.forEach((item) => {
          item.active = false;
          if (item.contentType === contentType) {
            item.active = true;
          }
        });
      };

      const setShowTag = (contentType: number, value: boolean) => {
        const option = tagOption.value.find((item) => {
          return item.contentType === contentType;
        });
        if (option) option.show = value;
      };

      // 获取作者对应课程信息
      const getAuthorCourseList = async () => {
        authorCourseList.value = (
          await getCourseList({
            author_id: id,
            community_id,
            content_type: getActiveTag(),
          })
        ).data;
      };

      onMounted(async () => {
        try {
          anchorInfo.value = new AuthorInfo(
            await getAuthor({
              author_id: id,
            }),
          );
          setShowTag(1, anchorInfo.value.show_map.course_contain);
          setShowTag(2, anchorInfo.value.show_map.video_contain);
          setShowTag(3, anchorInfo.value.show_map.article_contain);
          loadingAuthor.value = false;
          getAuthorCourseList();
        } catch (e) {
          console.log(e);
        }
      });

      onBeforeUnmount(() => {
        if (env === 'mockuai') {
          const { api } = window.lib.bridge;
          api.navigationBarConfig({
            hiddenNavigationBar: false,
            backButtonStyle: 1,
          });
        }
      });

      const onClickTag = (contentType: number) => {
        setActiveTag(contentType);
        getAuthorCourseList();
      };

      return {
        anchorInfo,
        loadingAuthor,
        authorCourseList,
        onClickTag,
        tagOption,
        navigateBack,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/helpers/helpers.scss';
  @import '@/mk-view/style/theme/theme.scss';

  .author-lecturer {
    min-height: 100vh;
    background-color: $color-bg-primary;
  }
  .header {
    position: relative;
    padding-top: px(125);
    background: url('https://mktv-in-cdn.mockuai.com/16213224338004643.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .icon-back {
      position: absolute;
      top: px(48);
      left: px(16);
      color: white;
      font-size: px(20);
    }
    .author-intro {
      margin-bottom: px(10);
      background: white;
      border-radius: px(10) px(10) px(2) px(2);
    }
  }

  .tags {
    margin-bottom: px(10);
    padding: 0 px(25);
    .tag {
      margin-right: px(10);
    }
  }
</style>
