<template>
  <router-view />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {},
  });
</script>

<style lang="scss">
  @import './style/index.scss';

  #app {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    color: #2c3e50;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: env(safe-area-inset-bottom);
  }

  .page {
    z-index: 1;
    -webkit-overflow-scrolling: touch;
    background-color: $color-bg-primary;
    height: 100%;
    overflow-y: auto;
  }
</style>
