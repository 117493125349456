
  import { defineComponent } from 'vue';
  import MkSkeleton from '@/mk-view/skeleton';
  import { Author } from '@/service';

  export default defineComponent({
    components: { MkSkeleton },
    props: {
      author: {
        type: Object,
        default() {
          return new Author();
        },
      },
      loading: {
        type: Boolean,
        default: false,
      },
      theme: {
        type: String,
        default: 'default',
      },
    },
    setup(props) {},
  });
