import request from '../core/request';
import { IQuestion } from './model';

export async function getQuestionList(data: any): Promise<{ question_list: IQuestion[] }> {
  return request.request<{ question_list: IQuestion[] }>({
    url: 'ec/school/course/question/list',
    method: 'GET',
    data,
  });
}

export async function addQuestion(data: any): Promise<any> {
  return request.request<any>({
    url: 'ec/school/course/question/add',
    method: 'POST',
    data,
  });
}
