
  import { defineComponent, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import color from '@/mk-view/utils/theme/color';
  import MkSwipe from '@/mk-view/swipe';
  import MkSwipeItem from '@/mk-view/swipe-item';
  import MkButton from '@/mk-view/button';
  import { getMaterialDetail } from '@/service';
  import { trackEvent } from '@/plugins/track/events';
  import MkImagePreview from '@/mk-view/image-preview';

  export default defineComponent({
    components: {
      MkSwipe,
      MkSwipeItem,
      MkButton,
    },
    props: {},
    setup() {
      const route = useRoute();
      const id = Number(route.query.id);
      const community_id = route.query.community_id ? Number(route.query.community_id) : '';

      const material = ref({
        main_title: '',
        sub_title: '',
        cover_img: '',
        description: '',
        content_type: 0,
        material_detail: {
          material_img: [''],
          share_copy: '',
        },
      });

      onMounted(async () => {
        material.value = await getMaterialDetail({
          id,
          community_id,
        });
      });

      const previewImage = (imageUrl: string) => {
        MkImagePreview({
          images: [imageUrl],
          showIndex: false,
        });
      };

      const onClickShare = async () => {
        trackEvent('2248', route, {});

        const { api } = window.lib.bridge;
        await api.communityMaterialShare({
          title: material.value.material_detail.share_copy,
          images: JSON.stringify(material.value.material_detail.material_img),
        });
      };

      return {
        color,
        material,
        onClickShare,
        previewImage,
      };
    },
  });
