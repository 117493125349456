<template>
  <div class="content">
    <img class="vip-bg" src="https://mktv-in-cdn.mockuai.com/16887097722461528.png" />
    <img class="vip-info" src="https://mktv-in-cdn.mockuai.com/16890595069217247.png" alt="" @click="openVip()" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  declare const window: any;

  export default defineComponent({
    props: {
      questionList: {
        type: Object,
      },
    },
    setup() {
      const openVip = () => {
        const ua = navigator.userAgent.toLowerCase();
        const isTTWebView = ua.includes('toutiaomicroapp');
        const isWeixin = ua.includes('micromessenger');

        if (isTTWebView) {
          window.tt.miniProgram.navigateTo({
            url: '/pages/module/open/index',
          });
        } else if (isWeixin) {
          window.wx.miniProgram.navigateTo({
            url: '/pages/module/open/service',
          });
        } else {
          window.ks.navigateTo({
            url: '/pages/module/open/index',
          });
        }
      };
      return { openVip };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/mk-view/style/theme/theme.scss';
  @import '@/mk-view/style/helpers/helpers.scss';

  .content {
    position: relative;
    margin: px(8) 0 0;

    .vip-bg {
      width: 100%;
      border-radius: 0 0 px(4) px(4);
    }

    .vip-info {
      position: absolute;
      top: 50%;
      left: 50%;
      width: px(252);
      height: px(269);
      margin: px(-134.5) 0 0 px(-126);
      border-radius: px(12);
    }
  }
</style>
