
  import { defineComponent, onMounted, ref } from 'vue';
  import MkSkeleton from '@/mk-view/skeleton';
  import MkEmpty from '@/mk-view/empty';

  export default defineComponent({
    components: {
      MkSkeleton,
      MkEmpty,
    },
    props: {
      coursewareList: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    setup() {
      const loading = ref(true);
      onMounted(() => {
        loading.value = false;
      });
      return { loading };
    },
  });
